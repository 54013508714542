import { useCallback, useState, useContext, useEffect } from 'react';
import { Form, Input, Button, Row, Col, theme as themeAntd } from 'antd';
import { useNavigate } from 'react-router-dom';

// @ts-ignore
// import ImagemLogo from './../../assets/imagens/logo.png';

import { ThemeContext } from './../../contexts/ThemeContext';
import { EmitError } from './../../utils/EmitError';
import { VerificaRota } from '../../services/VerificaSePodeAcessar';
import './style.css';
import Loading from '../../components/Loading';
import useLocalStorage from '../../hooks/useLocalStorage';
import useAuth from '../../hooks/useAuth';
import toast, { clearToasts } from '../../utils/toast';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import PanelUsersService from '../../services/http/services/PanelUsersService';
import { EmitSuccessAlert } from '../../utils/EmitSuccessAlert';

interface interfInputs {
    password: string,
    confirmPassword: string,
}

export default function FirstAccess() {
    const navigate = useNavigate();

    const {
        token: { colorBgContainer },
    } = themeAntd.useToken();

    const {
        getUser,
    } = useLocalStorage();

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setError,
    } = useForm<interfInputs>({
        defaultValues: {
            password: '',
            confirmPassword: '',
        }
    })

    const { user, signOut } = useAuth();

    const { theme } = useContext(ThemeContext);

    const [isSubmiting, setIsSubmiting] = useState(false);

    useEffect(() => {
        let arrayUrl = window.location.pathname.split("/");
        if (VerificaRota(arrayUrl, navigate)) {
            if (!getUser()?.first_access) {
                navigate('/institucional');
                return;
            }

            toast({
                type: 'info',
                text: 'Bem-vindo!',
                description: 'Por favor, redefina a sua senha!',
                duration: 1000 * 60 * 10,
                key: 'primeiroAcesso_aiosdashdhloas'
            })
        }
    }, [navigate, getUser])

    const onSubmit: SubmitHandler<interfInputs> = useCallback(async (data): Promise<void> => {
        if (data.password !== data.confirmPassword) {
            setError('confirmPassword', { message: 'Senhas não coincidem' });
            return;
        }
        try {
            setIsSubmiting(true);

            const userId = getUser()?.uuid;
            if (!userId) {
                EmitError('Error');
                return;
            }

            const result = await PanelUsersService.updatePanelUserPassword(data.password);

            localStorage.setItem('@GovfacilGestor:user', JSON.stringify(
                { user: result.data }
            ));

            clearToasts();
            reset();
            setIsSubmiting(false);

            let arrayUrl = result.data.name.split(" ");

            EmitSuccessAlert('Senha atualizada com sucesso!', `Bem-vindo, ${arrayUrl[0]}!`);
            navigate('/institucional');

        } catch (err) {
            setIsSubmiting(false);

            let msgErro: any = (err as Error);

            if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                EmitError('Sessão expirada');
                // notification.error({
                //     message: 'Sessão expirada',
                //     description:
                //         'Sua sessão expirou, realize o login novamente!',
                // });
                navigate('/');
            } else {
                EmitError(err, true, 'primeiroacesso_dkwdfaiweyi');
            }
        }
    }, [getUser, navigate, reset, setError]);


    return <Row
        className="fotoFundoLogin"
        gutter={24}
        style={{
            maxHeight: "100vh",
            margin: 0,
            backgroundColor: colorBgContainer,
        }}
        align="middle"
    >
        <Col
            flex={1}
            style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                maxWidth: "483px",
                margin: "0 auto",
            }}
        >
            <Loading loading={isSubmiting}>

                <Form
                    style={{
                        backgroundColor: "#FFF",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                        borderRadius: "10px",
                    }}
                    layout='vertical'
                    name="formPrimeiroAcesso"
                    onFinish={handleSubmit(onSubmit)}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                backgroundColor: "#0065AA",
                                color: "#FFF",
                                fontSize: "30px",
                                fontWeight: "700",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                borderRadius: "10px 10px 0 0",
                                textAlign: "center",
                                fontFamily: "Exo 2",
                                marginBottom: "10px",
                            }}
                        >PAINEL ADMINISTRATIVO</div>


                        <img
                            style={{
                                marginTop: "40px",
                                width: "270px",
                                // margin: '20px'
                            }}
                            alt="Logo Govfácil Gestor"
                            src={"/logoGestor.png"}
                        ></img>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: 30,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginTop: 20,
                                marginBottom: 50,
                            }}>
                            <Form.Item
                                style={{
                                    width: '70%',
                                    marginBottom: 15,
                                    maxWidth: '100%',
                                }}
                                label="Nova senha"
                                required
                                validateStatus={errors.password ? 'error' : 'success'}
                                help={errors.password?.message}
                            >
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Por favor, digite sua nova senha!'
                                        },
                                        minLength: {
                                            value: 8,
                                            message: 'A senha precisa ter no mínimo 8 caracteres!'
                                        }
                                    }}
                                    render={({ field }) => <Input.Password {...field} autoComplete="off" placeholder="Nova senha" />}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{
                                    width: '70%',
                                    marginBottom: 15
                                }}
                                label="Confirme a nova senha"
                                required
                                validateStatus={errors.confirmPassword ? 'error' : 'success'}
                                help={errors.confirmPassword?.message}
                            >
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Por favor, confirme sua nova senha!'
                                        }
                                    }}
                                    render={({ field }) => <Input.Password {...field} autoComplete="off" placeholder="Confirme a nova senha" />}
                                />
                            </Form.Item>

                            <Button
                                type="primary"
                                htmlType='submit'
                                style={{
                                    width: "70%",
                                    backgroundColor: "#0065AA",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    borderRadius: "5px",
                                    color: "#FFF",

                                }}
                                disabled={isSubmiting}
                            >
                                Continuar
                            </Button>
                            <Button
                                type="primary"
                                style={{
                                width: "70%",
                                marginTop: 10,
                                backgroundColor: "#EF4040",
                                fontSize: "15px",
                                fontWeight: "600",
                                borderRadius: "5px",
                                color: "#FFF" }}
                                htmlType='button'
                                onClick={() => {
                                    signOut();
                                    navigate('/')
                                }}
                            >
                                {`Sair`}
                            </Button>
                        </div>
                    </div>
                </Form>
            </Loading>
        </Col>
    </Row>

    // return (
    //     <Row
    //         gutter={24}
    //         style={{
    //             maxHeight: "100vh",
    //             margin: 0
    //         }}
    //         align="middle"
    //     >
    //         <Col
    //             className='fotoFundoPrimeiroAcesso'
    //             style={{
    //                 filter: theme === 'light' ? 'grayscale(0%)' : 'grayscale(100%)',
    //             }}
    //             flex={3}
    //         >
    //             {/* <img style={{
    //                 width: '400px'
    //             }} src={ImagemLogo}></img> */}
    //         </Col>
    //         <Col
    //             flex={1}
    //             style={{
    //                 height: '100vh',
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 justifyContent: 'center',
    //                 maxWidth: '563px'
    //             }}
    //         >
    //             <Loading loading={isSubmiting} >
    //                 <Form
    //                     name="formPrimeiroAcesso"
    //                     style={{
    //                         width: '100%'
    //                     }}
    //                     layout="vertical"
    //                     onFinish={handleSubmit(onSubmit)}
    //                 >
    //                     <div style={{
    //                         display: 'flex',
    //                         flexDirection: 'column',
    //                         alignItems: 'center',
    //                         justifyContent: 'center'
    //                     }}>
    //                         {/* <img style={{
    //                             width: '290px',
    //                             // margin: '20px'
    //                         }} src={ImagemLogo}></img> */}
    //                     </div>
    //                     <div style={{
    //                         display: 'flex',
    //                         flexDirection: 'column',
    //                         margin: 30
    //                     }}>
    //                         <Form.Item
    //                             style={{
    //                                 marginBottom: 15,
    //                                 maxWidth: '100%',
    //                             }}
    //                             label="Nova senha"
    //                             required
    //                             validateStatus={errors.password ? 'error' : 'success'}
    //                             help={errors.password?.message}
    //                         >
    //                             <Controller
    //                                 name="password"
    //                                 control={control}
    //                                 rules={{
    //                                     required: {
    //                                         value: true,
    //                                         message: 'Por favor, digite sua nova senha!'
    //                                     },
    //                                     minLength: {
    //                                         value: 8,
    //                                         message: 'A senha precisa ter no mínimo 8 caracteres!'
    //                                     }
    //                                 }}
    //                                 render={({ field }) => <Input.Password { ...field } autoComplete="off" placeholder="Nova senha" />}
    //                             />
    //                         </Form.Item>
    //                         <Form.Item
    //                             style={{
    //                                 marginBottom: 15
    //                             }}
    //                             label="Confirme a nova senha"
    //                             required
    //                             validateStatus={errors.confirmPassword ? 'error' : 'success'}
    //                             help={errors.confirmPassword?.message}
    //                         >
    //                             <Controller
    //                                 name="confirmPassword"
    //                                 control={control}
    //                                 rules={{
    //                                     required: {
    //                                         value: true,
    //                                         message: 'Por favor, confirme sua nova senha!'
    //                                     }
    //                                 }}
    //                                 render={({ field }) => <Input.Password { ...field } autoComplete="off" placeholder="Confirme a nova senha" />}
    //                             />
    //                         </Form.Item>
    //                         <Button
    //                             type="primary"
    //                             style={{ width: "100%" }}
    //                             htmlType='submit'
    //                         >
    //                             Continuar
    //                         </Button>
    //                         <div
    //                             style={{
    //                                 display: 'flex',
    //                                 alignItems: 'center',
    //                                 justifyContent: 'center'
    //                             }}
    //                         >
    //                             <Button
    //                                 type="link"
    //                                 style={{ marginTop: 10 }}
    //                                 htmlType='button'
    //                                 onClick={() => {
    //                                     signOut();
    //                                     navigate('/')
    //                                 }}
    //                             >
    //                                 {`Sair`}
    //                             </Button>
    //                         </div>
    //                     </div>
    //                 </Form>
    //             </Loading>
    //         </Col>
    //     </Row>
    // );
};
