import { useCallback, useEffect, useState } from "react";

import { toastEventManager } from "../../../utils/toast";
import { interfMessage } from "../interfaces";

export default function useToastContainer() {
    const [messages, setMessages] = useState<Array<interfMessage>>([]);

    useEffect(() => {
        function handleAddToast({ key, text, description, type, duration }: interfMessage) {
            setMessages((prevState) => !key || !prevState.find((element) => element.key === key) ? [
                ...prevState,
                {
                    id: Math.random(),
                    key,
                    text,
                    description,
                    type,
                    duration,
                },
            ] : prevState);
        }

        function handleClearToasts() {
            setMessages([]);
        }

        toastEventManager.on('addtoast', handleAddToast);
        toastEventManager.on('cleartoasts', handleClearToasts);

        return () => {
            toastEventManager.removeListener('addtoast', handleAddToast);
            toastEventManager.removeListener('cleartoasts', handleClearToasts);
        };
    }, []);

    const handleRemoveToast = useCallback((id: number) => {
        setMessages((prevState) => prevState.filter((message) => message.id !== id));
    }, []);

    return {
        messages,
        handleRemoveToast,
    }
}