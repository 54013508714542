import toast from "../toast";
import { notification } from 'antd';

export const EmitError = (error: any, emitAlert: boolean = true, notificationKey: undefined | string = undefined) => {
    let errorMsg = new Error(error);

    if (error.name === 'CanceledError') {
        return;
    }

    if (errorMsg?.message === 'Error: Network Error') {
        toast({
            type: 'error',
            text: 'Você está sem conexão com a internet!',
            duration: 10000,
        });

        return;
    }

    if (errorMsg?.message === 'AxiosError: Request failed with status code 401') {

        notification.error({
            message: 'Sessão expirada',
            description: 'Sua sessão expirou, faça login novamente!',
            duration: 10000,
        });

        //Limpa o localStorage
        localStorage.clear();
        window.location.href = '/';

        return;
    }

    if (error?.response?.data?.message) {
        toast({
            key: notificationKey,
            type: 'error',
            text: error.response.data.message,
            duration: 10000,
        })

        return;
    }

    if (process.env.NODE_ENV === 'development') console.log(error);

    if (emitAlert) {
        toast({
            key: notificationKey,
            type: 'error',
            text: 'Erro',
            description: 'Entre em contato com o suporte!',
            duration: 10000,
        })
    }
}
