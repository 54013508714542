import axios
    // , { AxiosError }
    from 'axios';
import { logout } from '../LStorage/logout';
import { getRefreshToken } from '../LStorage/UsuarioERefresh';
import { checkExpiredToken, getToken } from './../../services/LStorage/token';
import { urlUsuarioSession, urlUsuariosSessionRefreshToken } from './../../services/urls';
import { EmitError } from '../../utils/EmitError';

// let isRefreshing = false;
// let failedRequestsQueue = [];

export function setupAPIClient() {

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    api.interceptors.request.use(async (config) => {

        if (
            config.url !== urlUsuariosSessionRefreshToken &&
            config.url !== urlUsuarioSession
        ) {
            if (getToken() !== undefined && checkExpiredToken(getToken())) {

                try {

                    let objRenovarToken = {
                        refresh_token: getRefreshToken(),
                    }

                    let result = await axios.post(urlUsuariosSessionRefreshToken, objRenovarToken);

                    localStorage.setItem('@GovfacilGestor:token', JSON.stringify(
                        { token: result.data.token }
                    ));

                    if (result.data?.refresh_token &&
                        typeof result.data?.refresh_token !== 'undefined') {

                        localStorage.setItem('@GovfacilGestor:rftoken', JSON.stringify(
                            result.data
                        ));
                    }


                    if (config?.headers?.Authorization) {
                        config.headers.Authorization = `Bearer ${result.data.token}`;
                    }

                    return config;

                } catch (error) {
                    logout();
                    EmitError(error);
                    // navigate("/");
                }
            } else {

                // Do something before request is sent
                return config;
            }
        }
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });


    return api;
}
