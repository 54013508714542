import { DownloadOutlined, EyeOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select, Upload } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { BiDownload, BiTrash } from "react-icons/bi";
import { inputNome } from "../GridStyle";
import { useEffect, useState } from "react";
import { ContentState, EditorState, convertFromHTML, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { urlNoticiaEntidadeImagem } from "../../../services/urls";
import { FaGlobe, FaInstagram } from "react-icons/fa";

const htmlToContentState = (html: any) => {
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return contentState;
  };

export const ModalFormNoticia = ({ isEditarNoticia, onEditorContentChange, isModalNoticiaOpen, handleNoticiaCancel, formNoticia, refFormNoticia, handleChangeImagensNoticia, fileList, setImagemModal, setIsModalImagemOpen, uploadImagensNoticia, loadingCampoUpload, loadingPage, onClickBotaoSalvarNoticia, noticiaDescricao, removerImagemNoticia, setFileList }: any) => {

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  useEffect(() => {
    if (isEditarNoticia && isModalNoticiaOpen && noticiaDescricao) {
      // Converte o HTML para ContentState
      const contentState = htmlToContentState(noticiaDescricao);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    } else {
        setEditorState(EditorState.createEmpty());
    }
  }, [isEditarNoticia, isModalNoticiaOpen, noticiaDescricao]);

  const getEditorContentAsHtml = () => {
    const contentState = editorState.getCurrentContent();
    //Converte o ContentState para HTML
    const html = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
    );
    onEditorContentChange(html);
  };

  const downloadImage = (url: string) => {
    // Faz uma solicitação para buscar os dados da imagem
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Cria um elemento 'a' para iniciar o download
        const element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download = url.split("/").pop() || "download";
        element.click();
      })
      .catch((error) => {
        window.open(url);
      });
  };



    return (
        <Modal
                title={isEditarNoticia ? "Editar Notícia" : "Adicionar Notícia"}
                width={1000}
                footer={[]}
                open={isModalNoticiaOpen}
                onOk={handleNoticiaCancel}
                onCancel={handleNoticiaCancel}
            >
                <Form
                    form={formNoticia}
                    ref={refFormNoticia}
                    name="formNoticias"
                    layout="vertical"
                    onFinishFailed={() => {}}
                >
                    {isEditarNoticia ?
                        <Row>
                            <Col span={10} style={{ textAlign: "start", marginBottom: 20 }}>
                                {/* ve se a notícia tem id_instagram */}
                                {formNoticia.getFieldValue("instagramId") ?
                                <span
                                    style={{
                                        fontSize: 15,
                                        fontWeight: 500,
                                        color: "#C13584",
                                    }}
                                    >
                                        <FaInstagram /> Instagram
                                </span>
                                :
                                <span
                                    style={{
                                        fontSize: 15,
                                        fontWeight: 500,
                                        color: "#1890ff",
                                    }}
                                    >
                                        <FaGlobe /> Site
                                </span>
                                }
                            </Col>
                        </Row>
                        :
                        <></>
                    }
                    <Row>
                        <Col hidden {...inputNome}>
                            <Form.Item
                                name="idNoticia"
                                label="id"
                                rules={[
                                    {
                                        required: false,
                                        message: "Por favor preencha o id!",
                                    },
                                ]}
                            >
                                <Input placeholder="id" disabled />
                            </Form.Item>
                            <Form.Item name="imagensNoticia" label="foto">
                                <Input placeholder="foto" disabled />
                            </Form.Item>
                            <Form.Item name="descricaoNoticia" label="descricao">
                                <Input placeholder="descricao" disabled />
                            </Form.Item>
                        </Col>
                        <Col span={14}>
                            <Form.Item
                                name="tituloNoticia"
                                label="Título"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor preencha o titulo!",
                                    },
                                ]}
                            >
                                <Input placeholder="Título" />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={9}>
                            <Form.Item
                                name="linkNoticia"
                                label="Link"
                                rules={[
                                    {
                                        pattern: new RegExp(
                                            /^(http|https):\/\/[^ "]+$/
                                        ),
                                        message: "Por favor preencha uma url válida!",
                                    },
                                ]}

                            >
                                <Input placeholder="ex: https://govfacil.com" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                name="dataNoticia"
                                label="Data"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor preencha a data!",
                                    },
                                ]}
                            >
                                <Input placeholder="Data" type="datetime-local" />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={11}>
                            <Form.Item
                                name="categoriaNoticia"
                                label="Categoria"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Por favor preencha a categoria!",
                                    },
                                ]}
                            >
                                <Input placeholder="Categoria" />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={5}>
                            <Form.Item
                                name="destaqueNoticia"
                                label="Destaque"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Por favor preencha o destaque!",
                                    },
                                ]}
                            >
                                <Select placeholder="Destaque" allowClear>
                                    <Select.Option value="1">Sim</Select.Option>
                                    <Select.Option value="0">Não</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="descricaoNoticiaItem"
                                label="Descrição"
                            >
                                 <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={setEditorState}
                                    editorStyle={{
                                        maxHeight: 250,
                                        border: "1px solid #e4e4e4",
                                        padding: 5,
                                        paddingBottom: 10,
                                        borderRadius: 2,
                                    }}
                                    toolbar={{
                                        options: [
                                            "inline",
                                            "fontSize",
                                            "fontFamily",
                                            "list",
                                            "textAlign",
                                            "colorPicker",
                                            "history",
                                            "emoji",
                                        ],
                                        inline: {
                                            options: ["bold", "italic"],
                                        },
                                        blockType: {
                                            options: [
                                                "Normal",
                                                "H1",
                                                "H2",
                                                "H3",
                                                "H4",
                                                "H5",
                                                "H6",
                                                "Blockquote",
                                                "Code",
                                            ],
                                        },
                                        fontFamily: {
                                            options: [
                                                "Montserrat-Bold",
                                                "Montserrat-SemiBold",
                                                "Montserrat-Medium",
                                                "Montserrat-Regular",
                                            ],
                                        },
                                        colorPicker: {
                                            colors: [
                                                "rgb(97,189,109)",
                                                "rgb(26,188,156)",
                                                "rgb(84,172,210)",
                                                "rgb(44,130,201)",
                                                "rgb(147,101,184)",
                                                "rgb(71,85,119)",
                                                "rgb(204,204,204)",
                                                "rgb(65,168,95)",
                                                "rgb(0,168,133)",
                                                "rgb(61,142,185)",
                                                "rgb(41,105,176)",
                                                "rgb(85,57,130)",
                                                "rgb(40,50,78)",
                                                "rgb(0,0,0)",
                                                "rgb(247,218,100)",
                                                "rgb(251,160,38)",
                                                "rgb(235,107,86)",
                                                "rgb(226,80,65)",
                                                "rgb(163,143,132)",
                                                "rgb(239,239,239)",
                                                "rgb(255,255,255)",
                                                "rgb(250,197,28)",
                                                "rgb(243,121,52)",
                                                "rgb(209,72,65)",
                                                "rgb(184,49,47)",
                                                "rgb(124,112,107)",
                                                "rgb(209,213,216)",
                                            ],
                                        },
                                        list: {
                                            options: [
                                                "unordered",
                                                "ordered",
                                            ],
                                        },
                                        textAlign: {
                                            // options: ['justify'],
                                            options: [
                                                "left",
                                                "center",
                                                "right",
                                                "justify",
                                            ],
                                            left: "left",
                                            center: "center",
                                            right: "right",
                                            link: "justify",
                                        },
                                    }}
                                    />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                // name="imagensNoticiaItem"
                                label="Imagens"
                                rules={
                                    [{
                                    required: true,
                                    message: "Por favor envie uma imagem!",
                                }]}
                            >
                                <Dragger
                                    multiple={true}
                                    accept=".png,.jpeg,.jpg"
                                    onChange={handleChangeImagensNoticia}
                                    fileList={fileList}
                                    name="file"
                                    customRequest={() => {}}
                                    // tira a lista de imagens
                                    showUploadList={false}
                                    style={{
                                        marginBottom: 10,
                                    }}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        Clique ou arraste o arquivo para esta
                                        área para fazer upload
                                    </p>
                                </Dragger>
                                {fileList?.map((file: any, index: any) => (
                                    <div
                                        key={index}
                                        className="image-container"
                                        style={{
                                            display: "inline-block",
                                            marginRight: 10,
                                        }}
                                    >
                                        <img
                                            key={Math.random()}
                                            src={
                                                file.originFileObj
                                                    ? URL.createObjectURL(
                                                          file.originFileObj
                                                      )
                                                    : urlNoticiaEntidadeImagem +
                                                      file
                                            }
                                            alt={file.name}
                                            style={{
                                                width: 128,
                                                borderRadius: 5,
                                                margin: 5,
                                            }}
                                        />
                                        <div className="image-icons"
                                        key={Math.random()}>
                                            <a
                                            className="download-icon"


                                                onClick={() => downloadImage(
                                                    file.originFileObj
                                                        ? URL.createObjectURL(
                                                              file.originFileObj
                                                          )
                                                        : urlNoticiaEntidadeImagem +
                                                              file

                                                )}
                                                download

                                            >
                                                <BiDownload />
                                            </a>
                                            <span
                                                className="view-icon"
                                                onClick={() => {
                                                    setImagemModal(
                                                        file.originFileObj
                                                            ? URL.createObjectURL(
                                                                  file.originFileObj
                                                              )
                                                            : urlNoticiaEntidadeImagem +
                                                                  file
                                                    );
                                                    setIsModalImagemOpen(true);
                                                }}
                                            >
                                                <EyeOutlined />
                                            </span>
                                            <span className="edit-icon"
                                                onClick={() => {
                                                    removerImagemNoticia(file, index)
                                                }}>


                                                <BiTrash />
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                        {/* {Array.isArray(fileList) && fileList.length > 0 && typeof fileList[0] === 'object' && 'uid' in fileList[0] && 'name' in fileList[0] && 'size' in fileList[0] ? (
                                <Button
                                    type="primary"
                                    name="upload"
                                    style={{
                                        width: "100%",
                                        borderRadius: "5px",
                                        marginBottom: "20px",
                                    }}
                                    onClick={uploadImagensNoticia}
                                    disabled={loadingCampoUpload}
                                >
                                    Fazer upload de {fileList.length} imagens
                                </Button>
                            ) : (
                                <></>
                            )} */}
                        </Col>
                        <Col span={24}>
                            {/* Botões de salvar  */}
                            <Row>
                                <Col span={24} style={{ textAlign: "end" }}>
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={handleNoticiaCancel}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                            type="primary"
                                            loading={loadingPage}
                                            onClick={() => {
                                                getEditorContentAsHtml();
                                                onClickBotaoSalvarNoticia()
                                            }
                                            }
                                        >
                                            {isEditarNoticia ? "Editar" : "Salvar"}
                                        </Button>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Modal>
    );
}
