import { Controller } from "react-hook-form";
import { Col, Form, Modal, Select } from "antd";

import useAuth from "../../hooks/useAuth";

import useSelectCityModal from "./useSelectCityModal";

export default function SelectCityModal() {
    const {
        control,
        errors,
        handleSubmit,
        handleSubmitForm,
        contracts,
        handleConfirmSelectContract,
        isSubmiting,
        refForm,
    } = useSelectCityModal();

    const { activeEntity, handleCloseSelectCityModal } = useAuth();

    const { isSelectCityModalOpen } = useAuth();

    return (
        <Modal
            open={isSelectCityModalOpen}
            okText="Confirmar"
            cancelText="Cancelar"
            onOk={handleConfirmSelectContract}
            onCancel={handleCloseSelectCityModal}
            maskClosable={false}
            closable={false}
            okButtonProps={{
                disabled: isSubmiting,
                loading: isSubmiting
            }}
            cancelButtonProps={{
                hidden: !activeEntity,
                disabled: isSubmiting,
            }}
        >
            <Form
                layout='vertical'
                onFinish={handleSubmit(handleSubmitForm)}
                ref={refForm}
            >
                <Col
                    span={24}
                >
                    <Form.Item
                        label="Contrato"
                        required
                        validateStatus={errors.contractId ? 'error' : 'success'}
                        help={errors.contractId?.message}
                    >
                        <Controller
                            name="contractId"
                            control={control}
                            rules={{
                                required: { value: true, message: 'Por favor, selecione o contrato que deseja acessar!' },
                            }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    disabled={!contracts}
                                    loading={!contracts}
                                    options={contracts?.map((contract: any) => ({ value: contract.id, label: contract.name, key: contract.id }))}
                                    placeholder='Selecione o contrato que deseja acessar'
                                />
                            )}
                        />
                    </Form.Item>
                </Col>
            </Form>
        </Modal>
    )
}
