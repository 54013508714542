import {useRef, useState } from "react";
import { Form, Input, Button, Row, Col, Alert, theme as themeAntd } from "antd";
import InputMask, { ReactInputMask } from 'react-input-mask';
// // @ts-ignore
// import ImagemLogo from './../../assets/imagens/logo.png';

import "./style.css";

// import { ThemeContext } from "../../contexts/ThemeContext";
import Loading from "../../components/Loading";
import useLogin from "./useLogin";
import CountdownTimer from "./components/CountdownTimer";
import { EmitError } from "../../utils/EmitError";
import toast from "../../utils/toast";

const Login = () => {
    const {
        user,
        handleSubmit,
        onSubmit,
        isSubmiting,
        isLoadingUser,
        msgEmail,
        formRef,
        submitPhoneAuth,
        submitEmailAuth,
        sendAuthCode,
        authCode,
    } = useLogin();

    // const { theme } = useContext(ThemeContext);

    const {
        token: { colorBgContainer },
    } = themeAntd.useToken();

    const [phoneAuth, setPhoneAuth] = useState(true);
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [type, setType] = useState("whatsapp");
    const [code, setCode] = useState("");
    const [timer, setTimer] = useState(0);

const inputMaskRef = useRef<ReactInputMask | null>(null);
const inputRadio = useRef<HTMLInputElement | null>(null);
    if (isLoadingUser) {
        return <Loading loading={isLoadingUser} />;
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if(phoneAuth){
            setTimer(60)
            submitPhoneAuth(phone, type);
            } else {
                if (validaCampos(email, password)) {
                    submitEmailAuth(email, password);
                }
            }
        }
    };

    const handleKeyPressCodigoAutenticacao = (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            sendAuthCode(code);
        }
    };

    const validaCampos = (email: string, password: string) => {
        if (email === '') {
            toast({
                type: 'error',
                text: 'Por favor, informe seu e-mail.',
                duration: 10000,
            });
            return false;
        }
        //Valida se o e-mail é válido
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast({
                type: 'error',
                text: 'E-mail inválido.',
                duration: 5000,
            });
            return false;
        }
        if (password === '') {
            toast({
                type: 'error',
                text: 'Por favor, informe sua senha.',
                duration: 5000,
            });
            return false;
        }
        return true;
    }
    return !user ? (
        <Row
            className="fotoFundoLogin"
            gutter={24}
            style={{
                maxHeight: "100vh",
                margin: 0,
                backgroundColor: colorBgContainer,
            }}
            align="middle"
        >
            <Col
                flex={1}
                style={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    maxWidth: "483px",
                    margin: "0 auto",
                }}
            >
                <Loading loading={isSubmiting}>

                    <Form
                        style={{
                            backgroundColor: "#FFF",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                            borderRadius: "10px",
                        }}
                        name="formLogin"
                        ref={formRef}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={handleSubmit(onSubmit)}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    backgroundColor: "#0065AA",
                                    color: "#FFF",
                                    fontSize: "30px",
                                    fontWeight: "700",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderRadius: "10px 10px 0 0",
                                    textAlign: "center",
                                    fontFamily: "Exo 2",
                                    marginBottom: "10px",
                                }}
                            >PAINEL ADMINISTRATIVO</div>


                            <img
                                style={{
                                    marginTop: "40px",
                                    width: "270px",
                                    // margin: '20px'
                                }}
                                alt="Logo Govfácil Gestor"
                                src={"/logoGestor.png"}
                            ></img>

                            {msgEmail && (
                                <div
                                    style={{
                                        float: "left",
                                        marginLeft: 30,
                                        marginRight: 30,
                                    }}
                                >
                                    <Alert
                                        message="Verifique seu email."
                                        type="success"
                                        style={{
                                            width: "100%",
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            marginTop: 10,
                                            marginBottom: -15,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        {phoneAuth ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: 30,
                                }}
                            >
                                {authCode && (
                                    <>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                marginTop: 20,
                                                paddingBottom: 0,
                                            }}>

                                            <Form.Item
                                                style={{
                                                    marginBottom: 15,
                                                    width: '60%',
                                                }}
                                            >
                                                <Input
                                                    style={
                                                        {
                                                            width: '100%',
                                                            padding: '6px 11px',
                                                            fontSize: '15px',
                                                            color: '#262626',
                                                            backgroundColor: '#fff',
                                                            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            transition: 'all .3s',
                                                            outline: 'none',
                                                        }
                                                    }
                                                    onKeyDown={handleKeyPressCodigoAutenticacao}
                                                    onChange={(e) => {
                                                        setCode(e.target.value);
                                                    }}
                                                    placeholder="Código de autenticação"
                                                />
                                            </Form.Item>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        color: "#555",
                                                    }}
                                                >
                                                    Receber código via:
                                                </label>
                                                {/* Input com Radio Button */}


                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        marginRight: "50px"
                                                    }}
                                                >


                                                    <CountdownTimer
                                                        timer={timer}
                                                        setTimer={setTimer}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >

                                                    <Button
                                                        type="link"
                                                        style={{
                                                            fontSize: "15px",
                                                            fontWeight: "600",
                                                            color: timer !== 0 ? "#344" : "#0065AA",
                                                        }}
                                                        {...(timer === 0 ? {} : { disabled: true })}
                                                        onClick={() => {
                                                            if (timer === 0) {
                                                                setTimer(60)
                                                                submitPhoneAuth(phone, type);
                                                            }
                                                        }}
                                                    >
                                                        Reenviar código
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: 20,
                                        paddingBottom: 50,
                                    }}>
                                    {!authCode ? (<>
                                        <Form.Item
                                            style={{
                                                marginBottom: 5,
                                                width: '70%',
                                            }}
                                        >
                                            <InputMask
                                                ref={inputMaskRef}
                                                style={
                                                    {
                                                        width: '100%',
                                                        padding: '6px 11px',
                                                        fontSize: '15px',
                                                        color: '#262626',
                                                        backgroundColor: '#fff',
                                                        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
                                                        border: "1px solid #0065AA",
                                                        borderRadius: '5px',
                                                        transition: 'all .3s',
                                                        outline: 'none',
                                                    }
                                                }
                                                onKeyDown={handleKeyPress}
                                                mask="(99) 9 9999-9999"
                                                maskChar="_"
                                                value={phone}
                                                onChange={(e: any) => setPhone(e.target.value)}
                                                placeholder="Digite seu número"
                                            />
                                        </Form.Item>
                                        {/* Label com Receber código via */}
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                marginTop: "10px",
                                            }}
                                        >
                                            <label
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    color: "#555",
                                                }}
                                            >
                                                Receber código via:
                                            </label>
                                            {/* Input com Radio Button */}


                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    marginRight: "30px"
                                                }}
                                            >
                                                <input
                                                    ref={inputRadio}
                                                    type="radio"
                                                    name="type"
                                                    value="whatsapp"
                                                    defaultChecked
                                                    style={{
                                                        marginRight: "7px",
                                                        width: "17px",
                                                        height: "17px",
                                                    }}
                                                    onChange={(e: any) => setType(e.target.value)}
                                                />
                                                <label
                                                    style={{
                                                        fontSize: "15px",
                                                        fontWeight: "600",
                                                        color: "#222",
                                                    }}
                                                >
                                                    WhatsApp
                                                </label>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <input
                                                    ref={inputRadio}
                                                    type="radio"
                                                    name="type"
                                                    value="sms"
                                                    style={{
                                                        marginRight: "7px",
                                                        width: "17px",
                                                        height: "17px",
                                                    }}
                                                    onChange={(e: any) => setType(e.target.value)}
                                                />
                                                <label
                                                    style={{
                                                        fontSize: "15px",
                                                        fontWeight: "600",
                                                        color: "#222",
                                                    }}
                                                >
                                                    SMS
                                                </label>
                                            </div>
                                        </div>


                                    </>) : ''}
                                    <Button
                                        type="primary"
                                        style={{
                                            width: "70%",
                                            backgroundColor: "#0065AA",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                            borderRadius: "5px",
                                            color: "#FFF",

                                        }}
                                        disabled={isSubmiting}
                                        onClick={() => {
                                            if (authCode) {
                                                sendAuthCode(code);
                                            } else {
                                                submitPhoneAuth(phone, type);
                                                setTimer(60)
                                            }
                                        }}
                                    >
                                        Continuar
                                    </Button>
                                    <Button
                                        type="primary"
                                        style={{
                                            marginTop: "10px",
                                            width: "70%",
                                            backgroundColor: "#1DA57A",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                            borderRadius: "5px",
                                            color: "#FFF",

                                        }}
                                        disabled={isSubmiting}
                                        onClick={() => {
                                            setPhoneAuth(false);
                                        }}
                                    >
                                        Login com E-mail
                                    </Button>
                                </div>
                            </div>
                        ) :
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: 30,
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: 20,
                                        paddingBottom: 50,
                                    }}>
                                    <Form.Item
                                        style={{
                                            marginBottom: 5,
                                            width: '70%',
                                        }}
                                    >
                                        <Input
                                            style={
                                                {
                                                    width: '100%',
                                                    padding: '6px 11px',
                                                    fontSize: '15px',
                                                    color: '#262626',
                                                    backgroundColor: '#fff',
                                                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
                                                    border: "1px solid #0065AA",
                                                    borderRadius: '5px',
                                                    transition: 'all .3s',
                                                    outline: 'none',
                                                }
                                            }
                                            onKeyDown={handleKeyPress}
                                            onChange={(e: any) => setEmail(e.target.value)}
                                            placeholder="Digite seu e-mail"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        style={{
                                            marginBottom: 5,
                                            width: '70%',
                                        }}
                                    >
                                        <Input.Password
                                            style={
                                                {
                                                    width: '100%',
                                                    padding: '6px 11px',
                                                    fontSize: '15px',
                                                    color: '#262626',
                                                    backgroundColor: '#fff',
                                                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
                                                    border: "1px solid #0065AA",
                                                    borderRadius: '5px',
                                                    transition: 'all .3s',
                                                    outline: 'none',
                                                }
                                            }
                                            onKeyDown={handleKeyPress}
                                            onChange={(e: any) => setPassword(e.target.value)}
                                            placeholder="Digite sua senha"
                                            type="password"
                                        />
                                    </Form.Item>

                                    <Button
                                        type="primary"
                                        style={{
                                            width: "70%",
                                            backgroundColor: "#0065AA",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                            borderRadius: "5px",
                                            color: "#FFF",

                                        }}
                                        disabled={isSubmiting}
                                        onClick={() => {
                                            if (validaCampos(email, password)) {
                                                submitEmailAuth(email, password);
                                            }
                                        }}
                                    >
                                        Continuar
                                    </Button>
                                    <Button
                                        type="primary"
                                        style={{
                                            marginTop: "10px",
                                            width: "70%",
                                            backgroundColor: "#1DA57A",
                                            fontSize: "15px",
                                            fontWeight: "600",
                                            borderRadius: "5px",
                                            color: "#FFF",

                                        }}
                                        disabled={isSubmiting}
                                        onClick={() => {
                                            setPhoneAuth(true);
                                        }}
                                    >
                                        Login com Telefone
                                    </Button>
                                </div>
                            </div>
                        }
                    </Form>
                </Loading>
            </Col>
        </Row>
    ) : null;
};

export default Login;
