import {Button, Col, Form, Input, Row, Upload, message, notification} from "antd";
import Title from "antd/es/typography/Title";
import { FaImage } from "react-icons/fa";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from './../../services/apiAxios';
import { EmitError } from "../../utils/EmitError";
import {inputFuncao, inputLogo, inputMensagem, inputNome, inputPerfilInstagram, preVisualizacao} from "./GridStyle";
import { urlContratoEntidade, urlEntidadeLogo } from "../../services/urls";
import { getToken } from "../../services/LStorage/token";
import useAuth from "../../hooks/useAuth";

export default function Institucional() {

    const { idParam }: any = useParams();
    const [dataEntity, setDataEntity] = useState(
        JSON.parse(localStorage.getItem("@GovfacilGestor:selectedEntity")!) || null);
    const refForm = useRef<any>(null);
    const {setUseractiveEntity, userCan} = useAuth();
    // const propsState = useSelector((state: stateGlobal) => state.global);
    const refIdContrato = useRef<any>(null);
    const refEntidadeInterface = useRef<null | any>(null);
    const refidUsuarioLogado = useRef<any>(null);
    const refImagemCarregadaLight = useRef<any>(null);
    const refImagemCarregadaDark = useRef<any>(null);
    const [loadingPage, setLoadingPage] = useState(false);
    const [isSalvarOrEditar, setIsSalvarOrEditar] = useState(false);
    const [editorState, setEditorState] = useState<any>(
        EditorState.createEmpty()
    );
    const [entidade, setEntidade] = useState();
    const [defaultEditorState, setdefaultEditorState] = useState<any>();
    const [loadingCampoUpload, setLoadingCampoUpload] =
        useState<boolean>(false);
    const [imageUrlLight, setImageUrlLight] = useState<any>(null);
    const [imageUrlDark, setImageUrlDark] = useState<any>(null);

    const downloadImage = (url: string) => {
        // Faz uma solicitação para buscar os dados da imagem
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                // Cria um elemento 'a' para iniciar o download
                const element = document.createElement("a");
                element.href = URL.createObjectURL(blob);
                element.download = "logo.png";
                element.click();
            })
            .catch((error) => {
                window.open(url);
            });
    };



    const comonentDidMount = useEffect((): void => {
        setIsSalvarOrEditar(true);
        const buscaDadosIniciais = async () => {


            try {
                let dadosLocalStorage: string | null =
                    localStorage.getItem("@GovFacil:token");
                let localUserData: any = null;
                if (typeof dadosLocalStorage == "string") {
                    localUserData = JSON.parse(dadosLocalStorage);
                    // return localUserData.user.nome
                }
                let dataEntity = JSON.parse(
                    localStorage.getItem("@GovfacilGestor:selectedEntity")!
                );
                setDataEntity(dataEntity);
                if (dataEntity) {

                    refEntidadeInterface.current = dataEntity?.entity;

                    await api.get(
                        urlContratoEntidade +
                        "/" +
                        dataEntity?.entity?.id,
                        {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                            },
                        }
                    ).then((result) => {
                        if (result) {
                            //caso for editar ativar o loading
                            setLoadingPage(true);

                            if (localUserData) {
                                refidUsuarioLogado.current = localUserData?.user?.id;
                            }

                            setIsSalvarOrEditar(false);

                            refForm.current.setFieldsValue({
                                id: result?.data?.uuid,
                                name: result?.data?.name,
                                url: result.data.url,
                                instagramProfile: result.data.instagramProfile,
                            });
                            setImageUrlLight(
                                urlEntidadeLogo +
                                "/" +
                                result.data.logo_light
                            );

                            setImageUrlDark(
                                urlEntidadeLogo +
                                "/" +
                                result.data.logo_dark
                            );


                            if (
                                result.data.description !== null && result.data.description !== undefined
                            ) {
                                const contentBlocks = htmlToDraft(
                                    result.data.description
                                );

                                const sampleEditorContent =
                                    ContentState.createFromBlockArray(
                                        contentBlocks.contentBlocks,
                                        contentBlocks.entityMap
                                    );
                                const editorState =
                                    EditorState.createWithContent(sampleEditorContent);

                                setEditorState(editorState);
                                // setdefaultEditorState(sampleEditorContent)
                            }
                        }
                    }).catch((error) => {
                        EmitError(error, true, "useEffect");
                    });

                }


                //Se tiver id
                if (refEntidadeInterface.current?.id) {
                }
                setLoadingPage(false);
            } catch (error) {
                setLoadingPage(false);
                EmitError(error, true, "useEffect");
            }
        };
        setDataEntity(
            JSON.parse(
                localStorage.getItem("@GovfacilGestor:selectedEntity")!
            )
        );
        buscaDadosIniciais();
    }, [idParam]);


    const getBase64 = useCallback((img: any, callback: any) => {
        try{
            const reader = new FileReader();
            reader.addEventListener("load", () => callback(reader.result));
            reader.readAsDataURL(img);
        } catch (error) {

        }
    }, []);

    const beforeUpload = useCallback((file: any) => {
        // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isJpgOrPng = file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("Somente arquivos png são suportados!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isJpgOrPng && isLt2M;
    }, []);

    const onchangeEditor = useCallback((editorState: any): void => {
        setEditorState(editorState);
    }, []);

    const handleChangeLight = useCallback((info: any) => {
        refImagemCarregadaLight.current = info;

        getBase64(info.file.originFileObj, (imageUrl: any) => {
            setLoadingCampoUpload(false);
            setImageUrlLight(imageUrl);
        });
    }, []);

    const handleChangeDark = useCallback((info: any) => {
        refImagemCarregadaDark.current = info;

        getBase64(info.file.originFileObj, (imageUrl: any) => {
            setLoadingCampoUpload(false);
            setImageUrlDark(imageUrl);
        });
    }, []);

    useEffect(() => {
        if (entidade !== undefined) {
            setEntidade(entidade);
        }
    }, [entidade]);

    const onClickBotaoEditar = useCallback((): void => {
        let dadosLocalStorage: string | null =
            localStorage.getItem("@GovFacil:Mensagem");
        let localUserData: any = null;
        if (typeof dadosLocalStorage == "string") {
            localUserData = JSON.parse(dadosLocalStorage);
        }
        const editarEntidadeContrato = async (values: any) => {
            try {
                const formData = new FormData();
                formData.append("description", values.description);
                formData.append("name", values.name);
                formData.append("url", values.url);
                formData.append("instagramProfile", values.instagramProfile);


                if (refImagemCarregadaLight.current !== null) {
                    formData.append(
                        "logo_light",
                        refImagemCarregadaLight.current.file.originFileObj
                    );
                }
                if (refImagemCarregadaDark.current !== null) {
                    formData.append(
                        "logo_dark",
                        refImagemCarregadaDark.current.file.originFileObj
                    );
                }

                const idEntidade = refForm.current.getFieldValue("id");
                let resultEntidade = await api.put(
                    urlContratoEntidade + "/" + idEntidade,
                    formData,
                    {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                setLoadingPage(false);
                notification.success({
                    message: "Sucesso",
                    description: "Entidade atualizada com sucesso!",
                });

                refForm.current.setFieldsValue({
                    name: resultEntidade.data.name,
                    url: resultEntidade.data.url,
                    instagramProfile: resultEntidade.data.instagramProfile,
                });

                setUseractiveEntity(resultEntidade.data);

            } catch (error) {
                EmitError(error, true, "useEffect");
            }
        };

        refForm.current
            .validateFields()
            .then((values: any) => {
                values.description = draftToHtml(
                    convertToRaw(editorState.getCurrentContent())
                );
                values.contratoId = refIdContrato.current;
                editarEntidadeContrato(values);
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: "Erro",
                    description: "Preencha os campos obrigatorios!",
                });
            });
    }, [editorState]);


    if(!userCan('manage_entity')) {
        return (
            <>
                <Title level={3}>Você não tem permissão para acessar essa página.</Title>
            </>
        );
    }

    return (
        <>
            {/* Titulo da página */}
            <Title level={2} style={{ marginTop: 20, marginBottom: 40 }}>
                Institucional
            </Title>
            <Form
                ref={refForm}
                name="formSendMensagens"
                layout="vertical"
                onFinishFailed={() => { }}
            >
                <Row>
                    <Col hidden>
                        <Form.Item
                            name="id"
                            label="id"
                            rules={[
                                {
                                    required: false,
                                    message: "Por favor preencha o id!",
                                },
                            ]}
                        >
                            <Input placeholder="id" disabled />
                        </Form.Item>
                    </Col>
                    <Col {...inputNome}>
                        <Form.Item
                            name="name"
                            label="Nome"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor preencha o nome!",
                                },
                            ]}
                        >
                            <Input placeholder="Nome" />
                        </Form.Item>
                    </Col>
                    <Col {...inputFuncao}>
                        <Form.Item
                            name="url"
                            label="Url"
                            rules={[
                                {
                                    type: "url",
                                    message:
                                        "Por favor preencha uma url válida!",
                                },
                            ]}
                        >
                            <Input
                                // addonBefore="https://"
                                placeholder="ex: https://govfacil.com.br"
                            />
                        </Form.Item>
                    </Col>
                    <Col {...inputPerfilInstagram}>
                        <Form.Item
                            name="instagramProfile"
                            label="Perfil Instagram"
                        >
                            <Input placeholder="Perfil do Instagram" />
                        </Form.Item>
                    </Col>
                    <Col {...inputMensagem}>
                        <Form.Item
                            name="description"
                            label="Descrição"
                            rules={[
                                {
                                    required: false,
                                    message: "Por favor digite a descrição!",
                                },
                            ]}
                        >
                            {/* <Input.TextArea style={{ maxHeight: 250, height: 150 }} /> */}
                            <Editor
                                editorState={editorState}
                                defaultEditorState={defaultEditorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                editorStyle={{
                                    maxHeight: 250,
                                    border: "1px solid #e4e4e4",
                                    padding: 5,
                                    paddingBottom: 10,
                                    borderRadius: 2,
                                }}
                                onEditorStateChange={onchangeEditor}
                                toolbar={{
                                    options: [
                                        "inline",
                                        "fontSize",
                                        "fontFamily",
                                        "list",
                                        "textAlign",
                                        "colorPicker",
                                        "history",
                                        "emoji",
                                    ],
                                    inline: {
                                        options: ["bold", "italic"],
                                    },
                                    blockType: {
                                        options: [
                                            "Normal",
                                            "H1",
                                            "H2",
                                            "H3",
                                            "H4",
                                            "H5",
                                            "H6",
                                            "Blockquote",
                                            "Code",
                                        ],
                                    },
                                    fontFamily: {
                                        options: [
                                            "Montserrat-Bold",
                                            "Montserrat-SemiBold",
                                            "Montserrat-Medium",
                                            "Montserrat-Regular",
                                        ],
                                    },
                                    colorPicker: {
                                        colors: [
                                            "rgb(97,189,109)",
                                            "rgb(26,188,156)",
                                            "rgb(84,172,210)",
                                            "rgb(44,130,201)",
                                            "rgb(147,101,184)",
                                            "rgb(71,85,119)",
                                            "rgb(204,204,204)",
                                            "rgb(65,168,95)",
                                            "rgb(0,168,133)",
                                            "rgb(61,142,185)",
                                            "rgb(41,105,176)",
                                            "rgb(85,57,130)",
                                            "rgb(40,50,78)",
                                            "rgb(0,0,0)",
                                            "rgb(247,218,100)",
                                            "rgb(251,160,38)",
                                            "rgb(235,107,86)",
                                            "rgb(226,80,65)",
                                            "rgb(163,143,132)",
                                            "rgb(239,239,239)",
                                            "rgb(255,255,255)",
                                            "rgb(250,197,28)",
                                            "rgb(243,121,52)",
                                            "rgb(209,72,65)",
                                            "rgb(184,49,47)",
                                            "rgb(124,112,107)",
                                            "rgb(209,213,216)",
                                        ],
                                    },
                                    list: {
                                        options: ["unordered", "ordered"],
                                    },
                                    textAlign: {
                                        // options: ['justify'],
                                        options: [
                                            "left",
                                            "center",
                                            "right",
                                            "justify",
                                        ],
                                        left: "left",
                                        center: "center",
                                        right: "right",
                                        link: "justify",
                                    },
                                }}
                            // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']
                            />
                        </Form.Item>
                    </Col>
                    <Col {...inputLogo} style={{ marginTop: 20 }}>
                        <Title level={4}>Imagem modo Light</Title>
                        <Upload
                            // name="file"
                            showUploadList={false}
                            customRequest={() => { }}
                            beforeUpload={beforeUpload}
                            onChange={handleChangeLight}
                        >
                            <Button
                                style={{
                                    width: "100%",
                                    borderRadius: "8px 8px 0px 0px",
                                }}
                                icon={<FaImage />}
                            >
                                Clique para adicionar imagem
                            </Button>
                            {imageUrlLight ? (
                                <div
                                style={{
                                    paddingBottom: 10,
                                        paddingTop: 10,
                                        backgroundColor: "#fff",
                                        //centralizar imagem
                                        display: "flex",
                                        justifyContent: "center",
                                }}>
                                <img
                                    src={imageUrlLight}
                                    alt="imagem modo light"
                                    style={{
                                        width: "100%",
                                        maxWidth: 150,
                                        minWidth: 200,
                                        //centralizar imagem


                                    }}
                                />
                                </div>
                            ) : (
                                <></>
                            )}
                        </Upload>

                        {imageUrlLight ? (
                            <>
                                <Button
                                    style={{
                                        width: "100%",
                                        maxWidth: 240,
                                        minWidth: 240,
                                        borderRadius: "0px 0px 8px 8px",
                                    }}

                                    type="primary"
                                    onClick={() => {
                                        downloadImage(imageUrlLight);
                                    }}
                                >
                                    Baixar Imagem
                                </Button>
                            </>
                        ) : (
                            <></>
                        )}
                        {/* Botão para fazer download da imagem */}
                    </Col>
                    <Col span={1} />

                    <Col {...preVisualizacao} style={{ marginTop: 20 }}>
                        <Title level={4}>Pré-visualização modo claro</Title>
                        {imageUrlLight ? (
                            <div
                                style={{
                                    backgroundColor: "#fff",
                                    padding: 0,
                                    borderRadius: 8,
                                    width: 200,
                                    height: 100,
                                    // shadow
                                    boxShadow:
                                        "0px 0px 10px rgba(0, 0, 0, 0.3)",
                                }}
                            >
                                <div
                                    style={{
                                        paddingTop: 5,
                                        flexDirection: "row",
                                        // justifyContent: 'space-between'
                                    }}
                                >
                                    <img
                                        style={{
                                            maxWidth: "80%", // Largura máxima
                                            minWidth: "50px", // Largura mínima
                                            maxHeight: "70px", // Altura máxima (ajuste ao seu tamanho desejado)
                                            paddingLeft: 10,
                                            paddingTop: 10,
                                        }}
                                        src={
                                            imageUrlLight
                                                ? imageUrlLight
                                                : "https://govfacil.com.br/wp-content/uploads/2021/04/LogoGovFacilLight.png"
                                        }
                                    />
                                </div>
                                <Title
                                    style={{
                                        fontSize: 13,
                                        color: "#555",
                                        alignItems: "center",
                                        paddingTop: 5,
                                        position: "absolute",
                                        bottom: 0,
                                        top: 105,
                                        paddingBottom: 5,
                                        paddingLeft: 10,
                                    }}
                                >
                                    {refForm.current?.getFieldValue("name")}
                                </Title>
                            </div>
                        ) : (
                            ""
                        )}
                    </Col>
                    <Col {...inputLogo} style={{ marginTop: 20 }}>
                        <Title level={4}>Imagem modo Dark</Title>
                        <Upload
                            // name="file"
                            // className="avatar-uploader"
                            showUploadList={false}
                            customRequest={() => { }}
                            beforeUpload={beforeUpload}
                            onChange={handleChangeDark}
                        >
                            {" "}
                            <Button
                                style={{
                                    width: "100%",
                                    borderRadius: "8px 8px 0px 0px",
                                }}
                                icon={<FaImage />}
                            >
                                Clique para adicionar imagem
                            </Button>
                            {imageUrlDark ? (
                                <div
                                style={{
                                    paddingBottom: 10,
                                        paddingTop: 10,
                                        backgroundColor: "#1D1D1D",
                                        //centralizar imagem
                                        display: "flex",
                                        justifyContent: "center",
                                }}>
                                <img
                                    src={imageUrlDark}
                                    alt="imagem modo dark"
                                    style={{
                                        width: "100%",
                                        maxWidth: 150,
                                        minWidth: 200,
                                        //centralizar imagem


                                    }}
                                />
                                </div>
                            ) : (
                                <></>
                            )}
                        </Upload>
                        {imageUrlDark ? (
                            <>
                                <Button
                                    style={{
                                        width: "100%",
                                        maxWidth: 240,
                                        minWidth: 240,
                                        borderRadius: "0px 0px 8px 8px",
                                    }}
                                    type="primary"
                                    onClick={() => {
                                        downloadImage(imageUrlDark);
                                    }}
                                >
                                    Baixar Imagem
                                </Button>
                            </>
                        ) : (
                            <></>
                        )}
                    </Col>
                    <Col span={1} />
                    <Col {...preVisualizacao} style={{ marginTop: 20 }}>
                        <Title level={4}>Pré-visualização modo escuro</Title>
                        {imageUrlDark ? (
                            <div
                                style={{
                                    backgroundColor: "#383838",
                                    padding: 0,
                                    borderRadius: 8,
                                    width: 200,
                                    height: 100,

                                    // shadow
                                    boxShadow:
                                        "0px 0px 10px rgba(0, 0, 0, 0.3)",
                                }}
                            >
                                <div
                                    style={{
                                        paddingTop: 5,
                                        flexDirection: "row",
                                        //  justifyContent: 'space-between'
                                    }}
                                >
                                    <img
                                        style={{
                                            maxWidth: "80%", // Largura máxima
                                            minWidth: "50px", // Largura mínima
                                            maxHeight: "70px", // Altura máxima (ajuste ao seu tamanho desejado)
                                            paddingLeft: 10,
                                            paddingTop: 10,
                                        }}
                                        src={
                                            imageUrlDark
                                                ? imageUrlDark
                                                : "https://govfacil.com.br/wp-content/uploads/2021/04/LogoGovFacilLight.png"
                                        }
                                    />
                                </div>
                                <Title
                                    style={{
                                        fontSize: 13,
                                        color: "#fff",
                                        alignItems: "center",
                                        paddingTop: 5,
                                        position: "absolute",
                                        bottom: 0,
                                        top: 105,
                                        paddingBottom: 5,
                                        paddingLeft: 10,
                                    }}
                                >
                                    {refForm.current?.getFieldValue("name")}
                                </Title>
                            </div>
                        ) : (
                            ""
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={22}>
                        {/* Botões de salvar  */}
                        <Row style={{ marginTop: 10 }}>
                            <Col span={24} style={{ textAlign: "end" }}>
                                {isSalvarOrEditar ? (
                                    <></>
                                ) : (
                                    <Button
                                        type="primary"
                                        loading={loadingPage}
                                        onClick={onClickBotaoEditar}
                                    >
                                        Atualizar
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    );
}
