import { interfPanelUser } from '../../../interfaces';
import privateHttpClient from '../privateHttpClient';
import PanelUserMapper from '../mappers/PanelUserMapper';
import { urlHttpGov } from '../../urls';
import ContractsService from './ContractsService';
import api from '../privateHttpClient';

class PanelUsersService {
    private baseURL: string = `${urlHttpGov}/user`;

    async listPanelUsers(signal: AbortSignal) {

        const { data } = await privateHttpClient.get(this.baseURL, { signal });

        const contracts = await ContractsService.listContracts(signal);
        data.forEach((user: any) => {
            user.contracts = [];
            contracts.forEach((contract: any) => {
                if (user.contract.includes(contract.id)) {
                    user.contract.splice(user.contract.indexOf(contract.id), 1);
                    user.contract.push(contract);
                }
            });
        });


        return data.map((user: any) => PanelUserMapper.toDomain(user));
    }

    createPanelUser(panelUser: interfPanelUser) {
        const persistencePanelUser = PanelUserMapper.toPersistence(panelUser);

        return privateHttpClient.post(this.baseURL, persistencePanelUser);
    }

    async getPanelUserById(id: number, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}`, { signal });

        return PanelUserMapper.toDomain(data);
    }

    updatePanelUser(id: number, panelUser: interfPanelUser) {
        const persistencePanelUser = PanelUserMapper.toPersistence(panelUser);

        return privateHttpClient.put(`${this.baseURL}/${id}`, persistencePanelUser);
    }

    deletePanelUser(id: number) {
        return privateHttpClient.delete(`${this.baseURL}/${id}`);
    }

    activeUser(id: number) {
        return privateHttpClient.patch(`${this.baseURL}/active/${id}`);
    }

    updatePanelUserPassword(new_password: string) {
        console.log('oi')
        return api.post(`${this.baseURL}/first_access`, { new_password });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PanelUsersService();
