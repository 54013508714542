import { interfContract } from '../../../interfaces';

class ContractMapper {
    toPersistence(domainContract: interfContract): any {
        return {
            id: domainContract.id,
            name: domainContract.name,
            contractId: domainContract.contractId,
            entity: domainContract.entity,
            created_at: domainContract.created_at,
            updated_at: domainContract.updated_at,
        }
    }

    toDomain(persistenceContract: any): interfContract {
        return {
            id: persistenceContract.id,
            name: persistenceContract.name,
            contractId: persistenceContract.contractId,
            entity: persistenceContract.entity,
            created_at: persistenceContract.created_at,
            updated_at: persistenceContract.updated_at,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ContractMapper();
