
// export const urlHttpGov = 'https://desenvolveapi.govfacilbrasil.com.br';//homologacao

export const urlHttpGov = process.env.REACT_APP_API_URL || 'https://gestorapihomologacao.govfacilbrasil.com.br';//homologacao

// export const urlHttpGov = 'https://desenvolveapi.govfacil.app';//producao

//SESSAO
export const awardImagesUrl = `${urlHttpGov}/image_award`;
export const urlMunicipios = urlHttpGov+'/municipios';
export const urlUsuarioSession = urlHttpGov+'/auth'
export const urlUsuariosSessionRefreshToken = urlHttpGov+'/auth/refresh_token'
export const urlContratoEntidade = urlHttpGov+'/entity'
export const urlContratoNoticias = urlHttpGov+'/news'
export const urlContratoEntidadeMembro = urlHttpGov+'/member'
export const urlContratoEntidadeUsuario = urlHttpGov+'/user'


export const urlEntidadeLogo = urlHttpGov+'/entity_logo';
export const urlNoticiaEntidadeImagem = urlHttpGov+'/news_image/';
export const urlMembroEntidadeFoto = urlHttpGov+'/member_image/';
