import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from '../pages/Login';
import Institucional from '../pages/Institucional';
import LayoutAdmin from '../components/LayoutAdmin';
import RequireAuth from './RequireAuth';
import FirstAccess from '../pages/FirstAccess';
import Noticias from '../pages/Noticias';
import Membros from '../pages/Membros';
import Perfil from '../pages/Perfil';

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />

                <Route element={<RequireAuth />}>
                    <Route path="/primeiro-acesso" element={<FirstAccess />} />

                    <Route element={<LayoutAdmin />}>
                        <Route path="/institucional" element={<Institucional />} />
                        <Route path="/noticias" element={<Noticias />} />
                        <Route path="/membros" element={<Membros />} />
                        <Route path="/perfil" element={<Perfil/>} />
                        {/* <Route path="/perfis/criar" element={<NewRole />} /> */}
                        {/* <Route path="/perfis/editar/:id" element={<EditRole />} /> */}

                        {/* <Route path="/usuarios" element={<PanelUsers />} /> */}
                        {/* <Route path="/usuarios-painel/criar" element={<NewPanelUser />} /> */}
                        {/* <Route path="/usuarios-painel/editar/:id" element={<EditPanelUser />} /> */}
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
