import jwt_decode from 'jwt-decode';
interface usuarioLogado {
    primeiro_acesso?: boolean,
    email: string | null,
    nome: string
}

export const getUsuario = (): usuarioLogado | undefined => {
    let dadosLocalStorage: string | null = localStorage.getItem('@GovfacilGestor:usuario');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);
        return localUserData.usuario
    } else {
        return undefined
    }
}

export const getIdUsuario = (): string | undefined => {
    let dadosLocalStorage: string | null = localStorage.getItem('@GovfacilGestor:rftoken');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);

        if (localUserData.refresh_token) {
            let decodedToken: any = jwt_decode(localUserData.refresh_token);

            if (decodedToken && decodedToken != null && decodedToken?.sub) {
                //retorna o id do usuario
                return decodedToken?.sub
            }
        }
        return undefined
    } else {
        return undefined
    }
}

export const getRefreshToken = (): string | undefined => {
    let dadosLocalStorage: string | null = localStorage.getItem('@GovfacilGestor:rftoken');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);
        return localUserData.refresh_token
    } else {
        return undefined
    }
}

export const removeRefreshToken = (): void => {
    let refreshToken: string | null = localStorage.getItem('@GovfacilGestor:rftoken');
    let parsedRefreshToken: any = null;
    if (typeof refreshToken == 'string') {
        parsedRefreshToken = JSON.parse(refreshToken);
    }

    if (parsedRefreshToken) {
        localStorage.removeItem('@GovfacilGestor:rftoken');
    }
}

export const removeUser = (): void => {
    let user: string | null = localStorage.getItem('@GovfacilGestor:user');
    let parsedUser: any = null;
    if (typeof user == 'string') {
        parsedUser = JSON.parse(user);
    }

    if (parsedUser) {
        localStorage.removeItem('@GovfacilGestor:usuario');
    }
}
