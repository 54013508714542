import { Button, Col, Divider, Form, Input, Modal, Row, Table, Tag, Typography, message, notification } from "antd";
import { FaEdit, FaSearch, FaTrash } from "react-icons/fa";
import moment from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { urlContratoEntidadeMembro, urlMembroEntidadeFoto } from "../../services/urls";
import { getToken } from "../../services/LStorage/token";
import { api } from './../../services/apiAxios';
import Title from "antd/es/typography/Title";
import { ModalFormMembro } from "./components/ModalFormMembro";
import { EmitError } from "../../utils/EmitError";
import { ModalImagem } from "../Noticias/components/ModalImagem";
import { ThemeContext } from "../../contexts/ThemeContext";
import useAuth from "../../hooks/useAuth";


export default function Noticias() {

    const { userCan } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const refFormMembro = useRef<any>(null);
    const [formMembro] = Form.useForm();
    const [isModalMembroOpen, setIsModalMembroOpen] = useState(false);
    const [isEditarMembro, setIsEditarMembro] = useState(false);
    const [fotoMembro, setFotoMembro] = useState("");
    const [membros, setMembros] = useState<any>([]);
    const [imagemModal, setImagemModal] = useState<any>(null);
    const [isModalImagemOpen, setIsModalImagemOpen] = useState(false);
    const [ordensMembros, setOrdensMembros] = useState<any>([]);
    const [membroEditar, setMembroEditar] = useState<any | null>(null);
    const {theme} = useContext(ThemeContext);
    const refContagemDoFiltroMembros = useRef<Array<any>>([]);
    const [dataEntity, setdataEntity] = useState(
        JSON.parse(localStorage.getItem('@GovfacilGestor:selectedEntity')!) || null
      );

    const onClickBotaoSalvarMembro = useCallback((): void => {
        //Pega os campos do formulário
        refFormMembro.current
            .validateFields()
            .then((values: any) => {

                values.entityId = dataEntity.entity.id;
                salvarMembro(values);
            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: "Erro",
                    description: "Preencha os campos obrigatorios!",
                });
            });
    }, []);

    const handleMembroCancel = () => {
        setIsModalMembroOpen(false);
    };

    const showModalMembro = async () => {
        let membroOrdens = membros.map((membro: any) => {
            //retorna ordem e id
            return { order: membro.order, id: membro.id };
        });

        setOrdensMembros(membroOrdens);
        formMembro.setFieldsValue({
            membroOrdens: membroOrdens,
        });

        setIsModalMembroOpen(true);
    };

    const beforeUploadMembro = useCallback(async (file: any): Promise<any> => {
        const isJpgOrPng =
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "image/jpg";
        if (!isJpgOrPng) {
            message.error("Somente arquivos [png, jpg, jpeg] são suportados!");
            return;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Imagem não pode ser maior que 2MB!");
            return;
        }

        //Pega proporção da imagem
        const proporcaoImagem = await getProporcaoImagem(file);

        if (proporcaoImagem.width !== proporcaoImagem.height) {
            message.error("Imagem deve ser quadrada!");
            return;
        }

        formMembro.setFieldsValue({
            fotoMembro: file,
        });

        setFotoMembro(file);
    }, []);

    const getProporcaoImagem = useCallback((file: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const { width, height } = img;
                resolve({ width, height });
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(file);
        });
    }, []);

    const getColumnSearchProps = useCallback(
        (dataIndex: any) => ({
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={(node) => {}}
                        placeholder={`Buscar ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            );
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => {
                            confirm();
                        }}
                        icon={<FaSearch />}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={clearFilters}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered: any) => (
                <FaSearch
                    style={{ color: filtered ? "#1890ff" : undefined }}
                />
            ),
            onFilter: (value: any, record: any) =>
                record[dataIndex]
                    ? record[dataIndex]
                          .toString()
                          .toLowerCase()
                          .includes(value.toLowerCase())
                    : "",
        }),
        []
    );

    const excluirMembro = useCallback((id: number) => {
        try {
            //Confirmação de exclusão
            Modal.confirm({
                title: "Excluir membro",
                content:
                    "Deseja realmente excluir o membro? (Essa ação não pode ser desfeita)",
                okText: "Sim",
                cancelText: "Não",
                onOk() {
                    api
                        .delete(urlContratoEntidadeMembro + "/" + id, {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                            },
                        })
                        .then((result) => {
                            notification.success({
                                message: "Sucesso",
                                description: "Membro excluido com sucesso!",
                            });
                            getMembros();
                        })
                        .catch((error) => {
                            notification.error({
                                message: "Erro",
                                description:
                                    "Não foi possivel excluir o membro!",
                            });
                        });
                },
                onCancel() {},
            });
        } catch (error) {
            EmitError(error, true, "useEffect");
        }
    }, []);

    const columnsMembros = [
        {
            title: "Foto",
            key: "photo",
            dataIndex: "photo",
            width: "10%",
            render: (photo: any) => {
                return (
                    <img
                        src={
                            photo !== " "
                                ? urlMembroEntidadeFoto + photo
                                : "/default-avatar.png"
                        }
                        alt="Foto"
                        onClick={() => {
                            setImagemModal(urlMembroEntidadeFoto + photo);
                            setIsModalImagemOpen(true);
                        }}
                        style={{
                            width: 50,
                            height: 50,
                            borderRadius: 5,
                            cursor: "pointer",
                        }}
                    />
                );
            },
        },
        {
            title: "Nome",
            dataIndex: "name",
            key: "name",
            width: "20%",
            sorter: (a: any, b: any) => a.name.localeCompare(b.name),
            ...getColumnSearchProps("name"),
            showSorterTooltip: false,
        },
        {
            title: "Cargo",
            dataIndex: "responsability",
            key: "responsability",
            width: "20%",
            sorter: (a: any, b: any) => a.responsability.localeCompare(b.responsability),
            ...getColumnSearchProps("responsability"),
            showSorterTooltip: false,
        },
        {
            title: "Mandato",
            dataIndex: "tenure",
            key: "tenure",
            width: "20%",
            sorter: (a: any, b: any) => a.tenure.localeCompare(b.tenure),
            ...getColumnSearchProps("tenure"),
            showSorterTooltip: false,
        },
        {
            title: "Ordem",
            dataIndex: "order",
            key: "order",
            width: "10%",
            sorter: (a: any, b: any) => a.order - b.order,
            showSorterTooltip: false,
            render: (val: any) => {
                return (
                    <span>
                        <Tag color={"green"} key={val}>
                            {val}
                        </Tag>
                    </span>
                );
            },
        },
        {
            title: "Criado em",
            dataIndex: "created_at",
            key: "created_at",
            width: "10%",
            showSorterTooltip: false,
            sorter: (a: any, b: any) =>
                a.created_at.localeCompare(b.created_at),
            render: (criado: any) => moment(criado).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Ações",
            dataIndex: "uuid",
            key: "uuid",
            width: "10%",
            render: (uuid: any, record: any) => (
                <span>
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        size="small"
                        onClick={() => {
                            setIsEditarMembro(true);

                            // Busca em noticias a noticia que vai ser editada
                            let membroFind = membros.find((membro: any) => {
                                return membro.uuid === uuid;
                            });


                            let membroOrdens = membros.map((membro: any) => {
                                return { order: membro.order, uuid: membro.uuid };
                            });
                            setOrdensMembros(membroOrdens);
                            formMembro.setFieldsValue({
                                membroOrdens: membroOrdens,
                            });
                            setIsModalMembroOpen(true);

                            // Carrega os dados da noticia com block
                            formMembro.setFieldsValue({
                                idMembro: membroFind?.uuid,
                                nomeMembro: membroFind?.name,
                                cargoMembro: membroFind?.responsability,
                                mandatoMembro: membroFind?.tenure,
                                fotoMembro: membroFind?.photo,
                                ordemMembro: membroFind?.order,
                                descricaoMembro: membroFind?.description,
                            });

                            setFotoMembro(membroFind?.photo);
                            setMembroEditar(membroFind?.id);
                            setIsModalMembroOpen(true);
                        }}
                    >
                        <FaEdit />
                    </Button>
                    <Divider type="vertical" />
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        danger
                        size="small"
                        onClick={() => {
                            excluirMembro(uuid);
                        }}
                    >
                        <FaTrash />
                    </Button>
                </span>
            ),
        },
    ];

    const comonentDidMount = useEffect((): void => {
        // setIsSalvarOrEditar(true);

        const buscaDadosIniciais = async () => {
            try {
                let dadosLocalStorage: string | null =
                    localStorage.getItem("@GovFacil:token");
                let localUserData: any = null;
                if (typeof dadosLocalStorage == "string") {
                    localUserData = JSON.parse(dadosLocalStorage);
                    // return localUserData.user.nome
                }



                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                // notification.error({
                //     message: "Erro",
                //     description: "Não foi possivel buscar dados!",
                // });
            }
        };
        //Se tiver id
        if(dataEntity.entity.id){
            getMembros();
        }

        buscaDadosIniciais();
    }, []);

    const getMembros = useCallback(() => {
        setIsLoading(true);
        const buscaDeDados = async () => {
            try {
                let resultMembros = await api.get(
                    urlContratoEntidadeMembro + '/entity/' + dataEntity.entity.id,
                    { headers: { Authorization: "Bearer " + getToken() } }
                );

                resultMembros.data.sort((a: any, b: any) => {
                    return (
                        moment(b.created_at).unix() -
                        moment(a.created_at).unix()
                    );
                });
                setMembros(resultMembros.data);
            } catch (error) {
                EmitError(error, true, "useEffect");
            }
        };
        setIsLoading(false);
        return buscaDeDados();
    }, []);

    const salvarMembro = useCallback(async (values: any) => {
        const ordensMembros = formMembro.getFieldValue("membroOrdens");

        // Converter values.ordemMembro para o mesmo tipo que ordem.ordem para uma comparação mais precisa
        const ordemMembro = parseInt(values.ordemMembro);
        if (ordensMembros !== undefined) {

            // Busca se existe ordem igual com id diferente
            let ordemEncontrada = ordensMembros.find((ordem: any) => {
                // Converter ordem.ordem para o mesmo tipo que values.ordemMembro
                const ordemNoArray = parseInt(ordem.order);
                return (
                    ordemNoArray === ordemMembro && ordem.uuid !== values.idMembro
                );
            });
            if (ordemEncontrada) {
                notification.error({
                    message: "Erro",
                    description: "Ordem já cadastrada em outro membro!",
                });
                return;
            }
        }

        try {
            const data = new FormData();
            data.append("name", values.nomeMembro);
            data.append(
                "description",
                values.descricaoMembro ? values.descricaoMembro : " "
            );
            data.append(
                "entityId", values.entityId
            );
            data.append("tenure", values.mandatoMembro);
            data.append("responsability", values.cargoMembro);
            data.append("order", values.ordemMembro);
            const foto = formMembro.getFieldValue("fotoMembro");
            data.append("photo", foto);


            if (values.idMembro) {
                api
                    .put(
                        urlContratoEntidadeMembro + "/" + values.idMembro,
                        data,
                        {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )
                    .then((result) => {
                        notification.success({
                            message: "Sucesso",
                            description: "Membro atualizado com sucesso!",
                        });
                        getMembros();
                        setIsModalMembroOpen(false);
                    })
                    .catch((error) => {
                        EmitError(error, true, "useEffect");
                    });
            } else {
                api
                    .post(urlContratoEntidadeMembro, data, {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((result) => {
                        notification.success({
                            message: "Sucesso",
                            description: "Membro salvo com sucesso!",
                        });
                        getMembros();
                        setIsModalMembroOpen(false);
                    })
                    .catch((error) => {
                        EmitError(error, true, "useEffect");
                    });
            }
        } catch (error) {
            let msgErro: any = error as Error;
            notification.error({
                message: "Erro",
                description: msgErro?.response?.data?.message
                    ? msgErro?.response?.data?.message
                    : "Entre em contato com o suporte!",
            });
            return false;
        }
    }, []);

    if(!dataEntity?.entity?.id){
        return (
            <>
                <Title level={3}>Cadastre o institucional antes de inserir os membros.</Title>
            </>
        );
    }


    if(!userCan('manage_member')) {
        return (
            <>
                <Title level={3}>Você não tem permissão para acessar essa página.</Title>
            </>
        );
    }

    return (
        <>
        <Title level={3}>Membros</Title>
        <Row style={{ marginTop: 10 }}>
                                <Col
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setIsEditarMembro(false);
                                            formMembro.setFieldsValue({
                                                idMembro: "",
                                                nomeMembro: "",
                                                descricaoMembro: "",
                                                cargoMembro: "",
                                                mandatoMembro: "",
                                                ordemMembro: "",
                                                fotoMembro: " ",
                                            });

                                            setFotoMembro(" ");
                                            showModalMembro();
                                        }}
                                    >
                                        <FaEdit />
                                        Adicionar Membro
                                    </Button>
                                </Col>
                            </Row>
                            <div style={{ overflowX: "auto" }}>
            <Table
                style={{
                    marginTop: 50
                }}
                columns={columnsMembros}
                dataSource={membros.map((membro: any) => {
                    return {
                        ...membro,
                        key: membro.uuid,
                    };
                })}
                title={(registros) => {
                    return (
                        <div
                            style={{
                                backgroundColor:
                                theme === "light"
                                        ? "#FAFAFA"
                                        : "#1D1D1D",
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5,
                            }}
                        >
                            <Typography>
                                {`Mostrando: ${registros.length
                                    } de ${refContagemDoFiltroMembros
                                        .current.length === 0
                                        ? membros.length
                                        : refContagemDoFiltroMembros
                                            .current.length
                                    } num total de ${membros.length
                                    } `}
                            </Typography>
                        </div>
                    );
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: "end",
                            }}
                        >
                            <Typography>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length
                                    } de ${refContagemDoFiltroMembros
                                        .current.length === 0
                                        ? membros.length
                                        : refContagemDoFiltroMembros
                                            .current.length
                                    } num total de ${membros.length
                                    } `}
                            </Typography>
                        </div>
                    );
                }}
                pagination={{
                    locale: { items_per_page: "" },
                    showSizeChanger: true,
                    defaultPageSize: 100,
                    position: ["topRight", "bottomRight"],
                    pageSizeOptions: ["10", "20", "50", "100"],
                }}
                // rowSelection={rowSelection}
                loading={isLoading}
                key={membros.uuid}
            >

            </Table>
            </div>

            <ModalFormMembro
                isModalMembroOpen={isModalMembroOpen}
                handleMembroCancel={handleMembroCancel}
                formMembro={formMembro}
                refFormMembro={refFormMembro}
                beforeUploadMembro={beforeUploadMembro}
                onClickBotaoSalvarMembro={onClickBotaoSalvarMembro}
                loadingPage={isLoading}
                isEditarMembro={isEditarMembro}
                fotoMembro={fotoMembro}
            />

            <ModalImagem
                isOpen={isModalImagemOpen}
                handleCancel={() => {
                    setIsModalImagemOpen(false);
                }}
                srcImage={imagemModal}
            />
        </>
    );
}
