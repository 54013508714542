import httpClient from "../../http/httpClient";
import { removeToken } from "../token";
import { removeRefreshToken, removeUser } from "../UsuarioERefresh";

export const logout = (): void => {
    removeToken();
    removeRefreshToken();
    removeUser();
    httpClient.defaults.headers.common['Authorization'] = undefined;
}
