import type { ThemeConfig } from 'antd';
import { global } from './global';

export const configDark: ThemeConfig = {
    token: {
        ...global.token,
        colorText: '#fff',
        colorBgLayout: '#000',
        colorPrimary: '#1875bb',
        colorBgContainer: '#141414',
        colorPrimaryBg: '#1F1F1F',
    },
    components: {
        Layout: {
            colorBgHeader: '#1F1F1F',
        },
        Menu: {
            colorText: '#FFFFFFA6',
            colorFillAlter: '#141414',
            itemSelectedBg: '#177ddc',
            itemSelectedColor: '#fff',
            colorBgContainer: '#1F1F1F',
        },
    }
}
