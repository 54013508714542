import { Button, Col, Divider, Form, Input, Modal, Row, Table, Tag, Typography, message, notification } from "antd";
import { convertDataUSParaBrasilEHora } from "../../utils/Conversores";
import { FaEdit, FaGlobe, FaInfoCircle, FaInstagram, FaSearch, FaTrash } from "react-icons/fa";
import moment from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { urlContratoEntidade, urlContratoNoticias } from "../../services/urls";
import { getToken } from "../../services/LStorage/token";
import { api } from './../../services/apiAxios';
import Title from "antd/es/typography/Title";
import { ModalFormNoticia } from "./components/ModalFormNoticia";
import { ModalImagem } from "./components/ModalImagem";
import { EmitError } from "../../utils/EmitError";
import { ThemeContext } from "../../contexts/ThemeContext";
import useAuth from "../../hooks/useAuth";


export default function Noticias() {

    const { userCan } = useAuth();
    const [isEditarNoticia, setIsEditarNoticia] = useState(false);
    const [noticias, setNoticias] = useState<any[]>([]);
    const [formNoticia] = Form.useForm();
    const [noticiaDescricao, setNoticiaDescricao] = useState<any>(null);
    const refFormNoticia = useRef<any>(null);
    const [fileList, setFileList] = useState<any>([]);
    const [isModalNoticiaOpen, setIsModalNoticiaOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [imagemModal, setImagemModal] = useState<any>(null);
    const [isModalImagemOpen, setIsModalImagemOpen] = useState(false);
    const [loadingCampoUpload, setLoadingCampoUpload] =
        useState<boolean>(false);
    const [noticiaEditar, setNoticiaEditar] =
        useState<any | null>(null);
    const [dataEntity, setdataEntity] = useState(
        JSON.parse(localStorage.getItem('@GovfacilGestor:selectedEntity')!) || null
    );

    const { theme } = useContext(ThemeContext);
    const refContagemDoFiltroNoticias = useRef<Array<any>>([]);


    const handleChangeImagensNoticia = (info: any) => {
        let newFileList = [...info.fileList];

        newFileList = newFileList.map((file) => {
            const isJpgOrPng =
                file.type === "image/jpeg" ||
                file.type === "image/png" ||
                file.type === "image/jpg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (file.type) {
                if (!isJpgOrPng) {
                    message.error("Somente arquivos JPG/PNG são suportados.");
                    // remove o arquivo inválido da lista
                    return false;
                }

                if (!isLt2M) {
                    message.error("A imagem deve ser menor que 2MB.");
                    // return; // Ignora o arquivo inválido
                    return false;
                }
                return file;
            }
            return file;
        });

        //remove os arquivos inválidos da lista
        newFileList = newFileList.filter((file) => {
            if (file !== false) {
                return file;
            }
        });

        formNoticia.setFieldsValue({
            imagensNoticia: newFileList,
        });

        setFileList(newFileList);
    };

    const handleEditorContentChange = (updatedHtml: any) => {
        formNoticia.setFieldsValue({
            descricaoNoticia: updatedHtml,
        });
    };

    const handleImagemCancel = () => {
        setIsModalImagemOpen(false);
    };

    const removerImagemNoticia = (file: any, index: number) => {
        Modal.confirm({
            title: "Remover imagem",
            content:
                "Deseja realmente remover a imagem? (Essa ação não pode ser desfeita)",
            okText: "Sim",
            cancelText: "Não",
            onOk() {

                if (!file.uid) {
                    let idNoticia =
                        refFormNoticia.current.getFieldValue("idNoticia");
                    api
                        .delete(
                            urlContratoNoticias +
                            "/" +
                            idNoticia + "/image",
                            {
                                headers: {
                                    Authorization: "Bearer " + getToken(),
                                },
                                data: {
                                    image: file,
                                },
                            }
                        )
                        .then((result) => {
                            notification.success({
                                message: "Sucesso",
                                description: "Imagem excluida com sucesso!",
                            });
                            getNoticias();
                            fileList.splice(index, 1);
                            setFileList(fileList);

                        })
                        .catch((error) => {
                            console.log(error)
                            notification.error({
                                message: "Erro",
                                description:
                                    error?.response?.data?.message ||
                                    "Não foi possivel excluir a imagem!",
                            });
                            return
                        });
                }
            },
            onCancel() { },
        });
    };

    const salvarNoticia = useCallback((values: any, escondeModal: boolean) => {
        setIsLoading(true);

        try {
            const data = new FormData();
            data.append("title", values.tituloNoticia);
            data.append("link", values.linkNoticia);
            data.append("description", values.descricaoNoticia);
            data.append("category", values.categoriaNoticia);
            data.append("date", values.dataNoticia);
            data.append(
                "emphasis",
                String(values.destaqueNoticia == 1 ? true : false)
            );
            data.append(
                "entityId", values.entityId
            );
            const imagens = formNoticia.getFieldValue("imagensNoticia");

            try {
                let dataImagens: any = [];
                //se imagens não for um array da um split
                if (!Array.isArray(imagens)) {
                    dataImagens = imagens.split(",");
                } else {
                    dataImagens = imagens;
                }
                if (imagens) {
                    for (let i = 0; i < dataImagens.length; i++) {
                        if (dataImagens[i].originFileObj) {
                            data.append(
                                "images",
                                dataImagens[i].originFileObj
                            );
                        } else {
                            data.append("images", dataImagens[i]);
                        }
                    }
                } else {
                    notification.error({
                        message: "Erro",
                        description: "Selecione uma imagem!",
                    });
                    return;
                }
            } catch (error) {
                console.log(error);
            }
            if (values.idNoticia) {
                api
                    .put(
                        urlContratoNoticias + "/" + values.idNoticia,
                        data,
                        {
                            headers: {
                                Authorization: "Bearer " + getToken(),
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )
                    .then((result) => {
                        notification.success({
                            message: "Sucesso",
                            description: "Notícia atualizada com sucesso!",
                        });
                        if (escondeModal) {
                            setIsModalNoticiaOpen(false);
                        }
                        setNoticiaDescricao("");
                        getNoticias();
                    })
                    .catch((error) => {
                        EmitError(error, true, "useEffect");
                    });
            } else {
                api
                    .post(urlContratoNoticias, data, {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((result) => {
                        notification.success({
                            message: "Sucesso",
                            description: "Notícia salva com sucesso!",
                        });
                        setIsModalNoticiaOpen(false);
                        getNoticias();
                    })
                    .catch((error) => {
                        EmitError(error, true, "useEffect");
                    });
            }
        } catch (error) {
            let msgErro: any = error as Error;
            notification.error({
                message: "Erro",
                description: msgErro?.response?.data?.message
                    ? msgErro?.response?.data?.message
                    : "Entre em contato com o suporte!",
            });
            return false;
        }
    }, []);


    const onClickBotaoSalvarNoticia = useCallback((): void => {
        setIsLoading(true);

        //Espera 1 segundo
        setTimeout(() => {
            refFormNoticia.current
                .validateFields()
                .then((values: any) => {
                    values.entityId = dataEntity.entity.id;
                    salvarNoticia(values, true);
                })
                .catch((errorInfo: any) => {
                    notification.error({
                        message: "Erro",
                        description: "Preencha os campos obrigatorios!",
                    });

                    setIsLoading(false);
                });
        }, 500);

        // setIsLoading(false);

    }, [noticiaEditar]);

    const uploadImagensNoticia = () => {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: "Bearer " + getToken(),
            },
        };

        // Pega o idNoticia no formulário
        let idNoticia = refFormNoticia.current.getFieldValue("idNoticia");
        const formData = new FormData();

        // Adicione cada arquivo da fileList ao formData
        fileList.forEach((file: any) => {
            formData.append(`imagens`, file.originFileObj);
        });

        api
            .patch(
                urlContratoEntidade + "/noticia/" + idNoticia,
                formData,
                config
            )
            .then((response) => {
                //Atualiza o valor do campo imagensNoticia no form
                refFormNoticia.current.setFieldsValue({
                    imagensNoticia: response.data.imagens,
                });

                message.success(`Todas as imagens foram enviadas com sucesso.`);
                getNoticias();
            })
            .catch((error) => {
                // Lide com erros aqui
                message.error(`Falha ao enviar imagens.`);
            });
    };

    const showModalNoticia = () => {
        formNoticia.setFieldsValue({
            // contratoEntidadeId: entidade,
        });
        setIsModalNoticiaOpen(true);
    };

    const handleNoticiaCancel = () => {
        setFileList([]);
        setIsModalNoticiaOpen(false);
    };

    const getNoticias = useCallback(() => {
        setIsLoading(true);
        const buscaDeDados = async () => {
            try {
                let resultNoticias = await api.get(
                    urlContratoNoticias + '/entity/' + dataEntity.entity.id,
                    { headers: { Authorization: "Bearer " + getToken() } }
                );
                resultNoticias.data.sort((a: any, b: any) => {
                    return (
                        moment(b.created_at).unix() -
                        moment(a.created_at).unix()
                    );
                });

                setNoticias(resultNoticias.data);
            } catch (error) {
                EmitError(error, true, "useEffect");
            }
        };
        setIsLoading(false);
        return buscaDeDados();
    }, []);

    const excluirNoticia = useCallback((id: number) => {
        try {
            //Confirmação de exclusão
            Modal.confirm({
                title: "Excluir notícia",
                content:
                    "Deseja realmente excluir a notícia? (Essa ação não pode ser desfeita)",
                okText: "Sim",
                cancelText: "Não",
                onOk() {
                    api.delete(urlContratoNoticias + '/' + id, {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                        },
                    })
                        .then((result: any) => {
                            notification.success({
                                message: "Sucesso",
                                description: "Notícia excluida com sucesso!",
                            });
                            getNoticias();
                        })
                        .catch((error: any) => {
                            notification.error({
                                message: "Erro",
                                description:
                                    "Não foi possivel excluir a notícia!",
                            });
                        });
                },
                onCancel() { },
            });
        } catch (error) {
            let msgErro: any = error as Error;
            notification.error({
                message: "Erro",
                description: msgErro?.response?.data?.message
                    ? msgErro?.response?.data?.message
                    : "Entre em contato com o suporte!",
            });
        }
    }, []);


    const getColumnSearchProps = useCallback(
        (dataIndex: any) => ({
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }: any) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={(node) => { }}
                        placeholder={`Buscar ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            );
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => {
                            confirm();
                        }}
                        icon={<FaSearch />}
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={clearFilters}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Limpar
                    </Button>
                </div>
            ),
            filterIcon: (filtered: any) => (
                <FaSearch
                    style={{ color: filtered ? "#1890ff" : undefined }}
                />
            ),
            onFilter: (value: any, record: any) =>
                record[dataIndex]
                    ? record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    : "",
        }),
        []
    );

    const columnsNoticias = [
        {
            title: "Origem",
            dataIndex: "instagramId",
            width: "5%",
            sorter: (a: any, b: any) => a.instagramId - b.instagramId,
            showSorterTooltip: false,
            render: (val: any) => {
                const icone = val ? (
                    <FaInstagram
                        style={{
                            color: "#C13584",
                            fontSize: 20,
                        }}
                    />
                ) : (
                    <FaGlobe
                        style={{
                            color: "#1890ff",
                            fontSize: 20,
                        }}
                    />
                );

                return (
                    <span>
                        {icone}
                    </span>
                );
            }
        },
        {
            title: "Titulo",
            dataIndex: "title",
            width: "20%",
            sorter: (a: any, b: any) => a.title.localeCompare(b.title),
            ...getColumnSearchProps("title"),
            showSorterTooltip: false,
            render: (val: any) => {
                let titulo = val.length > 45 ? val.substring(0, 45) + "..." : val;
                return (
                    <span style={{ textTransform: "capitalize" }}>{titulo}</span>
                );
            }
        },
        {
            title: "Categoria",
            dataIndex: "category",
            width: "15%",
            sorter: (a: any, b: any) => {
                return a.category?.localeCompare(b.category);
            },
            showSorterTooltip: false,
            render: (val: any) => {
                return (
                    <span style={{ textTransform: "capitalize" }}>{val}</span>
                );
            },
        },
        {
            title: "Data",
            dataIndex: "date",
            width: "11%",
            render: (val: any) => {
                return convertDataUSParaBrasilEHora(val);
            },
            showSorterTooltip: false,
        },
        {
            title: "Destaque",
            dataIndex: "emphasis",
            width: "5%",
            render: (val: any) => {
                return (
                    <span>
                        <Tag color={val ? "green" : "red"} key={val}>
                            {val ? "Sim" : "Não"}
                        </Tag>
                    </span>
                );
            },
        },
        {
            title: "Criado em",
            dataIndex: "created_at",
            key: "created_at",
            width: "11%",
            showSorterTooltip: false,
            sorter: (a: any, b: any) =>
                a.created_at.localeCompare(b.created_at),
            render: (criado: any) => moment(criado).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Ações",
            dataIndex: "uuid",
            width: "10%",
            render: (uuid: any, record: any) => (
                <span>
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        size="small"
                        onClick={() => {
                            setIsEditarNoticia(true);
                            // Busca em noticias a noticia que vai ser editada
                            let noticiaFind = noticias.find((noticia) => {
                                return noticia.uuid === uuid;
                            });

                            // Carrega os dados da noticia com block
                            formNoticia.setFieldsValue({
                                idNoticia: noticiaFind?.uuid,
                                tituloNoticia: noticiaFind?.title,
                                linkNoticia: noticiaFind?.link,
                                categoriaNoticia: noticiaFind?.category,
                                destaqueNoticia: noticiaFind?.emphasis
                                    ? "Sim"
                                    : "Não",
                                dataNoticia: moment(noticiaFind?.date).format(
                                    "YYYY-MM-DD HH:mm"
                                ),
                                descricaoNoticia: noticiaFind?.description,
                                imagensNoticia: noticiaFind?.images,
                                instagramId: noticiaFind?.instagramId,
                            });
                            setNoticiaDescricao(noticiaFind?.description);
                            // setFileList(noticia?.imagens?.split(","));
                            //Verifica se tem imagens
                            if (
                                noticiaFind?.images !== " " ||
                                noticiaFind?.images.length > 2
                            ) {
                                setFileList(noticiaFind?.images);
                            } else {
                                setFileList([]);
                            }

                            setIsModalNoticiaOpen(true);
                        }}
                    >
                        <FaEdit />
                    </Button>
                    <Divider type="vertical" />
                    <Button
                        style={{
                            borderRadius: 5,
                            margin: 1,
                        }}
                        type="primary"
                        danger
                        size="small"
                        onClick={() => {
                            excluirNoticia(uuid);
                        }}
                    >
                        <FaTrash />
                    </Button>
                </span>
            ),
        },
    ];


    const comonentDidMount = useEffect((): void => {
        // setIsSalvarOrEditar(true);

        const buscaDadosIniciais = async () => {
            try {
                let dadosLocalStorage: string | null =
                    localStorage.getItem("@GovFacil:token");
                let localUserData: any = null;
                if (typeof dadosLocalStorage == "string") {
                    localUserData = JSON.parse(dadosLocalStorage);
                    // return localUserData.user.nome
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                // notification.error({
                //     message: "Erro",
                //     description: "Não foi possivel buscar dados!",
                // });
            }
        };
        //Se tiver id
        if (dataEntity.entity.id) {
            getNoticias();
        }

        buscaDadosIniciais();
    }, []);

    if (!dataEntity?.entity?.id) {
        return (
            <>
                <Title level={3}>Cadastre o institucional antes de inserir as notícias.</Title>
            </>
        );
    }

    if (!userCan('manage_news')) {
        return (
            <>
                <Title level={3}>Você não tem permissão para acessar essa página.</Title>
            </>
        );
    }

    return (
        <>
            <Title level={3}>Notícias</Title>
            <Row style={{ marginTop: 10 }}>
                <Col
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Button
                        type="primary"
                        onClick={() => {
                            setIsEditarNoticia(false);
                            formNoticia.setFieldsValue({
                                idNoticia: "",
                                tituloNoticia: "",
                                linkNoticia: "",
                                categoriaNoticia: "",
                                destaqueNoticia: "Não",
                                descricaoNoticia: "",
                                imagensNoticia: "",
                                dataNoticia:
                                    moment().format(
                                        "YYYY-MM-DD"
                                    ),
                            });
                            setFileList([]);
                            setNoticiaDescricao("");
                            showModalNoticia();
                        }}
                    >
                        <FaEdit />
                        Adicionar Notícia
                    </Button>
                </Col>
            </Row>
            {/* div pra responsive */}
            <div style={{ overflowX: "auto" }}>
                <Table
                    style={{
                        marginTop: 50,
                    }}
                    columns={columnsNoticias}
                    dataSource={noticias.map((item) => ({ ...item, key: item.uuid }))}
                    // rowSelection={rowSelection}
                    loading={isLoading}
                    title={(registros) => {
                        return (
                            <div
                                style={{
                                    backgroundColor:
                                        theme === "light"
                                            ? "#FAFAFA"
                                            : "#1D1D1D",
                                    padding: 10,
                                    margin: -8,
                                    marginBottom: 0.5,
                                }}
                            >
                                <Typography>
                                    {`Mostrando: ${registros.length
                                        } de ${refContagemDoFiltroNoticias
                                            .current.length === 0
                                            ? noticias.length
                                            : refContagemDoFiltroNoticias
                                                .current.length
                                        } num total de ${noticias.length
                                        } `}
                                </Typography>
                            </div>
                        );
                    }}
                    footer={(registros) => {
                        return (
                            <div
                                style={{
                                    textAlign: "end",
                                }}
                            >
                                <Typography
                                >
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length
                                        } de ${refContagemDoFiltroNoticias
                                            .current.length === 0
                                            ? noticias.length
                                            : refContagemDoFiltroNoticias
                                                .current.length
                                        } num total de ${noticias.length
                                        } `}
                                </Typography>
                            </div>
                        );
                    }}
                    pagination={{
                        locale: { items_per_page: "" },
                        showSizeChanger: true,
                        defaultPageSize: 100,
                        position: ["topRight", "bottomRight"],
                        pageSizeOptions: ["10", "20", "50", "100"],
                    }}
                >

                </Table>
            </div>


            <ModalImagem
                isOpen={isModalImagemOpen}
                handleCancel={handleImagemCancel}
                srcImage={imagemModal}
            />

            <ModalFormNoticia
                isEditarNoticia={isEditarNoticia}
                isModalNoticiaOpen={isModalNoticiaOpen}
                handleNoticiaCancel={handleNoticiaCancel}
                formNoticia={formNoticia}
                refFormNoticia={refFormNoticia}
                handleChangeImagensNoticia={handleChangeImagensNoticia}
                fileList={fileList}
                setImagemModal={setImagemModal}
                setIsModalImagemOpen={setIsModalImagemOpen}
                uploadImagensNoticia={uploadImagensNoticia}
                loadingCampoUpload={loadingCampoUpload}
                loadingPage={isLoading}
                onClickBotaoSalvarNoticia={onClickBotaoSalvarNoticia}
                noticiaDescricao={noticiaDescricao}
                onEditorContentChange={handleEditorContentChange}
                removerImagemNoticia={removerImagemNoticia}
                setFileList={setFileList}
            />
        </>
    );
}
