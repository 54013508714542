import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import useAuth from "../../hooks/useAuth";

import { EmitError } from "../../utils/EmitError";
import { interfSignInData } from "../../interfaces";
import axios from "axios";
import { urlHttpGov } from "../../services/urls";
import toast from "../../utils/toast";

export default function useLogin() {
    const {
        loading: isLoadingUser,
        user,
        signIn,
        signInWithPhoneCode,
        userCan
    } = useAuth();

    const [params] = useSearchParams();
    const isParamEmail = params.get("email");

    const navigate = useNavigate();
    const formRef = useRef<any>(null);

    const [isSubmiting, setIsSubmiting] = useState(false);
    const [msgEmail, setMsgEmail] = useState(false);
    const [authCode, setAuthCode] = useState(false);

    const {
        control,
        handleSubmit,
        formState: { errors, },
        reset
    } = useForm<interfSignInData>({
        defaultValues: {
            email: '',
            phone: '',
            password: '',
        }
    });

    useEffect(() => {
        if (user) {

            if(user.first_access && user.email){
                navigate('/primeiro-acesso')
                return;
            }


            if(userCan('manage_entity')){
                navigate('/institucional')
                return;
            }

            if(userCan('manage_news')){
                navigate('/noticias')
                return;
            }

            if(userCan('manage_member')){
                navigate('/membros')
                return;
            }
        }
    }, [user, userCan, navigate]);

    useEffect(() => {
        if (isParamEmail) {

            const emailParam = String(isParamEmail);

            if (emailParam === 'true') {

                setMsgEmail(true)
            } else {
                setMsgEmail(false)
            }

        }

    }, [isParamEmail])

    const onSubmit: SubmitHandler<interfSignInData> = useCallback(async (data) => {
        try {
            setIsSubmiting(true);
            const loggedIn = await signIn(data);

            setIsSubmiting(false);
            if (loggedIn) {
                reset();
            }

        } catch (error) {
            setIsSubmiting(false);


            EmitError(error, true, 'login_dkwdfaiweyi')
        }
    }, [reset]);

    const onClickForgotPassword = useCallback(() => {
        navigate('/recuperar-senha')
    }, [navigate]);

    const submitPhoneAuth = useCallback(async (phone: string, type: string) => {

        //Retira a formatação do telefone
        phone = phone.replace(/\D/g, '');
        try {
            setIsSubmiting(true);
            const result = await axios.post(urlHttpGov + '/verification-code', { phone, type });
            setIsSubmiting(false);

            if (result) {
                setAuthCode(true);

                toast({
                    type: 'success',
                    text: 'Código enviado!',
                    duration: 5000,
                });

            }
        } catch (error) {
            setIsSubmiting(false);

            EmitError(error, true, 'login_dkwdfaiweyi')
        }
    }, []);

    const submitEmailAuth = useCallback(async (email: string, password: string) => {
        try {
            setIsSubmiting(true);
            const loggedIn = await signIn({ email, password });
            setIsSubmiting(false);
            if (loggedIn) {
                reset();
            } else {
                toast({
                    key: 'login_errorro38739827r',
                    type: 'error',
                    text: 'Erro',
                    description: 'Não foi possivel logar, verifique seus dados!',
                    duration: 10000,
                })
            }
        } catch (error) {
            setIsSubmiting(false);
            // EmitError(error, true, 'login_dkwdfaiweyi')
        }
    }, []);

    const sendAuthCode = useCallback(async (code: string) => {

        try {
            setIsSubmiting(true);
            const loggedIn = await signInWithPhoneCode(code);
            setIsSubmiting(false);
            if (loggedIn) {
                reset();
            }
        } catch (error) {
            setIsSubmiting(false);

            EmitError(error, true, 'login_dkwdfaiweyi')
        }
    }
        , []);

    return {
        isLoadingUser,
        user,
        isSubmiting,
        formRef,
        control,
        msgEmail,
        handleSubmit,
        authCode,
        errors,
        onSubmit,
        onClickForgotPassword,
        submitPhoneAuth,
        submitEmailAuth,
        sendAuthCode
    }
}
