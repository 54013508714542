import { useCallback } from "react";
import jwt_decode from 'jwt-decode';
import { interfCity, interfContract, interfPanelUser } from "../interfaces";

export default function useLocalStorage() {

    const getToken = useCallback((): string | undefined => {
        let localStorageData: string | null = localStorage.getItem('@GovfacilGestor:token');
        let parsedLocalStorageData: any = null;

        if (typeof localStorageData == 'string') {
            parsedLocalStorageData = JSON.parse(localStorageData);
            return parsedLocalStorageData.token;
        }

        return undefined;
    }, []);

    const getRefreshToken = useCallback((): string | undefined => {
        let localStorageData: string | null = localStorage.getItem('@GovfacilGestor:rftoken');
        let parsedLocalStorageData: any = null;

        if (typeof localStorageData == 'string') {
            parsedLocalStorageData = JSON.parse(localStorageData);
            return parsedLocalStorageData.refresh_token;
        }

        return undefined;
    }, []);

    const getUser = useCallback((): interfPanelUser | undefined => {
        let localStorageData: string | null = localStorage.getItem('@GovfacilGestor:user');
        let parsedLocalStorageData: any = null;

        if (typeof localStorageData == 'string') {
            parsedLocalStorageData = JSON.parse(localStorageData);
            return parsedLocalStorageData.user
        }

        return undefined;
    }, []);

    const getSelectedContractFromStorage = useCallback((): interfContract | undefined => {
        let localStorageData: string | null = localStorage.getItem('@GovfacilGestor:selectedEntity');
        let parsedLocalStorageData: any = null;

        if (typeof localStorageData == 'string') {
            parsedLocalStorageData = JSON.parse(localStorageData);
            return parsedLocalStorageData.contract;
        }

        return undefined;
    }, []);

    const getUserId = useCallback((): string | undefined => {
        let localStorageData: string | null = localStorage.getItem('@GovfacilGestor:rftoken');
        let parsedLocalStorageData: any = null;
        if (typeof localStorageData == 'string') {
            parsedLocalStorageData = JSON.parse(localStorageData);

            if (parsedLocalStorageData.refresh_token) {
                let decodedToken: any = jwt_decode(parsedLocalStorageData.refresh_token);
                if (decodedToken && decodedToken != null && decodedToken?.uuid) {
                    //retorna o id do usuario

                    return decodedToken?.uuid
                }
            }

            return undefined;
        }

        return undefined;
    }, []);

    const removeTokenFromLocalStorage = useCallback((): void => {
        const token: string | null = localStorage.getItem('@GovfacilGestor:token');
        let parsedToken: any = null;
        if (typeof token == 'string') {
            parsedToken = JSON.parse(token);
        }

        if (parsedToken) {
            localStorage.removeItem('@GovfacilGestor:token');
        }
    }, []);

    const removeRefreshTokenFromLocalStorage = useCallback((): void => {
        let refreshToken: string | null = localStorage.getItem('@GovfacilGestor:rftoken');
        let parsedRefreshToken: any = null;
        if (typeof refreshToken == 'string') {
            parsedRefreshToken = JSON.parse(refreshToken);
        }

        if (parsedRefreshToken) {
            localStorage.removeItem('@GovfacilGestor:rftoken');
        }
    }, []);

    const removeUserFromLocalStorage = useCallback((): void => {
        let user: string | null = localStorage.getItem('@GovfacilGestor:user');
        let parsedUser: any = null;
        if (typeof user == 'string') {
            parsedUser = JSON.parse(user);
        }

        if (parsedUser) {
            localStorage.removeItem('@GovfacilGestor:user');
        }
    }, []);

    const removeSelectedCityFromLocalStorage = useCallback((): void => {
        let city: string | null = localStorage.getItem('@GovfacilGestor:selectedEntity');
        let parsedCity: any = null;
        if (typeof city == 'string') {
            parsedCity = JSON.parse(city);
        }

        if (parsedCity) {
            localStorage.removeItem('@GovfacilGestor:selectedEntity');
        }
    }, []);

    return {
        getToken,
        getRefreshToken,
        getUser,
        getUserId,
        getSelectedContractFromStorage,
        removeTokenFromLocalStorage,
        removeRefreshTokenFromLocalStorage,
        removeUserFromLocalStorage,
        removeSelectedCityFromLocalStorage,
    }

}
