import { Button, Card, Col, Form, Input, Row, notification } from "antd";
import Title from "antd/es/typography/Title";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from './../../services/apiAxios';
import { EmitError } from "../../utils/EmitError";
import { urlContratoEntidadeUsuario } from "../../services/urls";
import { getToken } from "../../services/LStorage/token";
import useLocalStorage from "../../hooks/useLocalStorage";
import { inputNome, inputTelefone, inputEmail, inputSenhaAtual, inputSenha, inputConfirmaSenha } from "./GridStyle";
import InputMask, { ReactInputMask } from 'react-input-mask';
import { ThemeContext } from "../../contexts/ThemeContext";
import useAuth from "../../hooks/useAuth";

export default function Perfil() {

    const { getUserId } = useLocalStorage();
    const refFormPerfil = useRef<any>(null);
    const [loadingPage, setLoadingPage] = useState(false);
    const [obrigatorioConfirm, setObrigatorioConfirm] = useState(false);
    const navigate = useNavigate();
    const [formPerfil] = Form.useForm();
    const [phone, setPhone] = useState('');
    const inputMaskRef = useRef<ReactInputMask | null>(null);
    const { theme } = useContext(ThemeContext);

    const {
        user,
    } = useAuth();

    const validaEmail = useCallback((email: string): boolean => {
        let er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);

        if (email === '' || !er.test(email)) { return false; }
        return true;

    }, []);

    const submitBotaoEditar = useCallback(() => {


        const salvarDadosEditar = async (obj: any) => {
            try {
                setLoadingPage(true);
                //Remove os caracteres especiais do telefone
                obj.phone = obj.phone.replace(/\D/g, '');
                const result = await api.put(urlContratoEntidadeUsuario + '/profile', obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram editados com sucesso!',
                });

                localStorage.setItem('@GovfacilGestor:user', JSON.stringify(
                    { user: result.data }
                ));


                setLoadingPage(false);
                // navigate('/institucional');

            } catch (error) {
                setLoadingPage(false);
                let msgErro: any = (error as Error);


                EmitError(error, true, 'perfil_dkwdfaiweyi')
            }
        }

        refFormPerfil.current?.validateFields()
            .then((values: any) => {

                salvarDadosEditar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    const comonentDidMount = useEffect((): void => {
        setLoadingPage(true);
        const buscaDadosIniciais = async () => {
            try {
                await api.get(
                    urlContratoEntidadeUsuario + "/" + getUserId(),
                    {
                        headers: {
                            Authorization: "Bearer " + getToken(),
                        },
                    }
                ).then((result) => {
                    if (result) {
                        refFormPerfil.current.setFieldsValue({
                            name: result?.data?.name,
                            phone: result.data?.phone,
                            email: result?.data?.email,
                        });

                        setPhone(result.data?.phone);
                        setLoadingPage(false);

                    }
                }).catch((error) => {
                    EmitError(error, true, "useEffect");
                });


            } catch (error) {
                EmitError(error, true, "useEffect");
            }
        };
        buscaDadosIniciais();
    }, []);

    return <>
        <Title level={2} style={{ marginTop: 20, marginBottom: 40 }}>
            Perfil
        </Title>

        <Form
            ref={refFormPerfil}
            form={formPerfil}
            name="formUpdatePerfil"
            layout="vertical"
            style={{ width: "100%" }}
            onFinishFailed={() => { }}
            autoComplete="off"

        >
            <Row gutter={12}>
                {/* Primeiro Card */}
                <Col span={24}>
                    <Card
                        title="Informações do Usuário"
                        style={{
                            width: "100%", marginBottom: 20,
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                            borderRadius: 10,
                            padding: 0,
                        }}
                    >
                        <Row gutter={36}>
                            <Col {...inputNome}>
                                <Form.Item
                                    name="name"
                                    label="Nome"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor preencha o nome!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Informe seu nome" />
                                </Form.Item>
                            </Col>
                            <Col {...inputTelefone}>
                                <Form.Item
                                    name="phone"
                                    label="Telefone"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor preencha o telefone!",
                                        },
                                    ]}
                                >
                                    <InputMask
                                        style={{
                                            width: '100%',
                                            height: 32,
                                            borderRadius: 5,
                                            border: theme === 'dark' ? '1px solid #424242' : '1px solid #d9d9d9',
                                            backgroundColor: theme === 'dark' ? '#141414' : '#fff',
                                            padding: '4px 11px',
                                            color: theme === 'dark' ? '#fff' : '#000',
                                            fontSize: 14,
                                            outlineWidth: 1,
                                            outlineOffset: 1,
                                            outlineColor: '#1890ff',


                                        }}
                                        ref={inputMaskRef}
                                        mask="(99) 9 9999-9999"
                                        maskChar=""
                                        value={phone}
                                        onChange={(e: any) => setPhone(e.target.value)}
                                        placeholder="Digite seu número"
                                    />

                                </Form.Item>


                            </Col>
                            {user && user?.email && (
                            <Col {...inputEmail}>

                                <Form.Item
                                    name="email"
                                    label="Email (Login)"
                                    rules={[
                                        () => ({
                                            validator(rule, value) {
                                                return new Promise((resolve: (value?: any) => void, reject) => {
                                                    if (value !== '' && value != null) {
                                                        if (validaEmail(value)) {
                                                            resolve();
                                                        } else {
                                                            reject("Email inválido");
                                                        }
                                                    } else {
                                                        reject("Email é obrigatório");
                                                    }
                                                });
                                            },
                                        }),

                                    ]}
                                >
                                    <Input autoComplete="off" placeholder="Digite seu email!" />
                                </Form.Item>
                            </Col>
                            )}
                        </Row>
                    </Card>
                </Col>
                {user && user?.email && (
                <Col span={24}>
                    <Card
                        title="Segurança"
                        style={{
                            width: "100%", marginBottom: 5,
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                            borderRadius: 10,
                            padding: 0,
                        }}
                    >
                        <Row gutter={36}>
                            <Col {...inputSenhaAtual}>
                                <Form.Item
                                    name="password"
                                    label="Senha Atual"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Por favor digite sua senha!',
                                        },
                                        () => ({
                                            validator(rule, value) {
                                                if (value?.length >= 1) {
                                                    setObrigatorioConfirm(true);
                                                }
                                                if (value?.length === 0) {
                                                    setObrigatorioConfirm(false);
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password autoComplete="off" placeholder="Senha" />
                                </Form.Item>
                            </Col>
                            <Col {...inputSenha}>
                                <Form.Item
                                    name="new_password"
                                    label="Nova Senha"
                                    rules={[
                                        {
                                            required: obrigatorioConfirm,
                                            message: 'Por favor digite sua nova senha!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('password') !== value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Senha está igual à anterior!');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password autoComplete="off" placeholder="Por favor digite nova senha!" />
                                </Form.Item>
                            </Col>
                            <Col {...inputConfirmaSenha}>
                                <Form.Item
                                    name="confirmPassword"
                                    label="Confirmar Senha"
                                    rules={[
                                        {
                                            required: obrigatorioConfirm,
                                            message: "Por favor preencha a senha!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('new_password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Senhas não conferem!');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password placeholder="Confirmar Senha" autoComplete="off" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>)}
            </Row>
            <Row>
                <Col span={24}>
                    {/* Botões de salvar  */}
                    <Row style={{ marginTop: 20 }}>
                        <Col span={24} style={{ textAlign: "end" }}>
                            <Button
                                type="primary"
                                loading={loadingPage}
                                onClick={() => {
                                    submitBotaoEditar();
                                }}
                            >
                                Atualizar
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>


        </Form>

    </>;
}
