import React, { useCallback, useContext } from 'react'
import { Outlet } from 'react-router-dom';
import { Button, theme as themeAnt, Layout, Drawer, Menu, Dropdown, Avatar, Typography, Radio } from 'antd';
import {
    MenuOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
} from '@ant-design/icons';

import './style.css';

import { ThemeContext } from '../../contexts/ThemeContext'
import ImagemLogoHorizontal from '../../assets/imagens/background.jpeg';
import useLayoutAdmin from './useLayoutAdmin';
import SelectCityModal from '../SelectCityModal';
import useAuth from '../../hooks/useAuth';
import { urlHttpGov } from '../../services/urls';
import { FaChevronDown, FaCircle, FaEdit, FaMapMarkerAlt, FaMoon, FaSun } from 'react-icons/fa';
// import CityName from './components/CityName';
// import CityImage from './components/CityImage';

const { Header, Sider, Content, Footer } = Layout;
const { Text } = Typography;

export default function LayoutAdmin() {
    const { token: {
        colorBgContainer,
        colorPrimaryBg
    } } = themeAnt.useToken();

    const { theme } = useContext(ThemeContext);

    const { activeEntity } = useAuth();

    const {
        abrirMenuLateral,
        clickMenuPrincipal,
        collapsed,
        collapsedWidth,
        itemsMenuPainelState,
        onClickMenuConfiguracoes,
        onCloseDrawer,
        onCollapse,
        posicaoMenu,
        setAbrirMenuLateral,
        visibleDrawer,
        itemsMenuConfiguracaoState,
        nomeUsuarioLogado,
        toggle,
        onCloseDrawerColor,
        visibleDrawerColor,
        onchangeRadioTheme,
    } = useLayoutAdmin();

    const getNameEntity = useCallback(() => {
        if (!activeEntity) {
            //Pega do localstorage
            const entity = localStorage.getItem('@GovfacilGestor:selectedEntity');
            return entity ? JSON.parse(entity).entity.name : '';
        }

        return activeEntity.name;
    }, [activeEntity]);

    return (
        <>

            <Drawer
                placement="left"
                closable={false}
                onClose={onCloseDrawer}
                open={visibleDrawer}
                key="left"
            >
                <Menu
                    mode="inline"
                    onClick={position => {
                        clickMenuPrincipal(position)
                    }}
                    defaultSelectedKeys={[posicaoMenu]}
                    selectedKeys={[posicaoMenu]}
                    forceSubMenuRender={false}
                    triggerSubMenuAction="click"
                    className="DrawerMenuPrincipal"
                    style={{
                        marginLeft: -24,
                        marginRight: -24,
                        background: theme === 'dark' ? colorPrimaryBg : '',
                    }}
                    items={itemsMenuPainelState}
                />
            </Drawer>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider
                    width={280}
                    collapsedWidth={0}
                    breakpoint="md"
                    onBreakpoint={broken => {
                        onCollapse(broken);
                    }}
                    collapsed={collapsed}
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,

                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {!collapsed ? (
                               <img
                                style={{
                                    width: 200,
                                    padding: 10,
                                }}
                                src={'/logo.png'}
                                alt='GovFácil'
                            />) : <img
                            style={{
                                width: 50,
                                marginLeft: 5,
                                padding: 8,
                            }}
                            src={'/icone.png'}
                            alt='GovFácil'
                        />}
                    </div>

                    {!collapsed ? (

                        <Text

                            style={{
                                width: '100%',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',

                                backgroundColor: '#fffff440',
                                marginTop: 10,
                                paddingTop: 10,
                                paddingBottom: 10,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                color: '#fff',
                                fontSize: 16,
                            }}
                        >
                            {getNameEntity()}
                        </Text>
                        ) : ''}


                    <Menu
                        theme={theme === 'light' ? 'dark' : undefined}
                        mode="inline"
                        style={{
                            borderInlineEnd: 'none',
                        }}

                        onClick={position => { clickMenuPrincipal(position) }}
                        selectedKeys={[posicaoMenu]}
                        onOpenChange={(openKeys) => setAbrirMenuLateral(openKeys)}
                        openKeys={abrirMenuLateral}
                        items={itemsMenuPainelState}
                    />
                </Sider>

                <Layout className={collapsed ? 'siteLayout2' : 'siteLayout'}>
                    <Header
                        style={{
                            position: 'fixed',
                            // zIndex: 99,
                            padding: 0,
                            width: collapsedWidth === 0 ? '100%' : collapsed ? 'calc(100% - 77px)' : 'calc(100% - 280px)',
                            backgroundColor: colorBgContainer
                        }}
                    >
                        <div style={{ float: "left" }}>
                            {React.createElement(
                                collapsedWidth === 0 ?
                                    MenuOutlined
                                    :
                                    collapsed ?
                                        MenuUnfoldOutlined : MenuFoldOutlined
                                ,
                                {
                                    className: 'trigger',
                                    onClick: toggle
                                }
                            )}
                        </div>

                        <Dropdown
                            className="dropDownMenu"
                            trigger={['click']}
                            menu={{
                                items: itemsMenuConfiguracaoState,
                                onClick: onClickMenuConfiguracoes
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: 10,
                                }}>

                            <Button type="text">
                                {
                                    collapsedWidth !== 0 &&
                                    <Text
                                        style={{
                                            marginRight: '6px'
                                        }}
                                    >
                                        {nomeUsuarioLogado}
                                    </Text>
                                }
                                <Avatar size={28} icon={<UserOutlined />} />
                            </Button>
                            </div>
                        </Dropdown>
                    </Header>
                    <Content
                        style={{
                            margin: '10px',
                            marginTop: '70px',
                            padding: '24px',
                            minHeight: '160px',
                            backgroundColor: colorBgContainer,
                            zIndex: 1,
                        }}
                    >
                        <Outlet />
                    </Content>
                    <Footer
                        style={{ textAlign: 'center', marginTop: -10, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}
                    >
                        Desenvolvido por GovFácil
                    </Footer>
                </Layout>
            </Layout>
            <Drawer
                title="Tema do sistema"
                placement="right"
                closable={false}
                onClose={onCloseDrawerColor}
                open={visibleDrawerColor}
            >
                <Radio.Group
                    onChange={onchangeRadioTheme}
                    defaultValue={theme}
                    size="large"
                >
                    <Radio.Button value="light">Claro</Radio.Button>
                    <Radio.Button value="dark">Escuro</Radio.Button>
                </Radio.Group>
            </Drawer>
            <SelectCityModal />
        </>
    )
}
