import { Outlet, Navigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth"
import Loading from "../../components/Loading";

export default function RequireAuth() {
    const { loading, user } = useAuth();
    const location = useLocation();

    if(loading) {
        return <Loading loading={loading} />
    }

    return (
        user ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />
    )
}