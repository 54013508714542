import privateHttpClient from '../privateHttpClient';
import ContractMapper from '../mappers/ContractMapper';
import { urlHttpGov } from '../../urls';

class ContractsService {
    private baseURL: string = `${urlHttpGov}/contract`;

    async listContracts(signal: AbortSignal) {
        const { data } = await privateHttpClient.get(this.baseURL, { signal });

        return data.map((contract: any) => ContractMapper.toDomain(contract));
    }

    createContract(contract: FormData) {
        return privateHttpClient.post(this.baseURL, contract, { headers: { 'content-type': 'multipart/form-data' } });
    }

    async getContractById(id: number, signal: AbortSignal) {
        const { data } = await privateHttpClient.get(`${this.baseURL}/${id}`, { signal });

        return ContractMapper.toDomain(data);
    }

    async updateContract(id: number, contract: FormData) {
        const { data } = await privateHttpClient.patch(`${this.baseURL}/${id}`, contract, { headers: { 'content-type': 'multipart/form-data' } });

        return ContractMapper.toDomain(data);
    }

    deleteContract(id: number) {
        return privateHttpClient.delete(`${this.baseURL}/${id}`);
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ContractsService();
