import toast from "../toast";

export const EmitSuccessAlert = (text: string = "Sucesso!", description: string | undefined = undefined, emitAlert: boolean = true, notificationKey: undefined | string = undefined) => {
    if (emitAlert) {
        toast({
            key: notificationKey,
            type: 'success', 
            text, 
            description
        })
    }
}
