import { ConfigProvider, theme as themeAntd } from 'antd';
import 'antd/dist/reset.css';
import locale from 'antd/es/locale/pt_BR';

import Routes from './routes/routes';
import { ThemeContext } from './contexts/ThemeContext';
import { configLight } from './styles/themes/light';
import { configDark } from './styles/themes/dark';
import ToastContainer from './components/Toast/ToastContainer';
import { useContext } from 'react';
import { AuthProvider } from './contexts/AuthContext';

function App() {
    const { darkAlgorithm, defaultAlgorithm } = themeAntd;

    const { theme } = useContext(ThemeContext);

    return (
        <ConfigProvider
            theme={{
                algorithm: theme === 'light' ? defaultAlgorithm : darkAlgorithm,
                token: theme === 'light' ? configLight.token : configDark.token,
                components: theme === 'light' ? configLight.components : configDark.components,
            }}
            locale={locale}
        >
            <AuthProvider>
                <ToastContainer />
                <Routes />
            </AuthProvider>
        </ConfigProvider>
    );
}

export default App;
