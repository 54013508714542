import { NavigateFunction } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import { getToken } from "./../LStorage/token";
import { getRefreshToken, getUsuario } from "../LStorage/UsuarioERefresh";

export const VerificaRota = (path: Array<string>, navigate: NavigateFunction, redirecionar = true, temPermissao = true) => {

    if (getToken() && getRefreshToken()) {

        if (getUsuario()?.primeiro_acesso) {

            navigate('/primeiro-acesso');

            return false
        }

        if (!temPermissao) {

            navigate('/municipios');

            return false
        }

        return true;
    } else {

        redirecionar && navigate('/');

        return false;

    }
}

export const validacaoPermissao = (permissao: Array<string>): boolean => {
    const token = getToken();

    if (token) {
        // sub é o uuid
        const user = jwt_decode<{ permissoes: Array<string>, sub: string }>(token);

        if (user.permissoes.length > 0) {

            //se o usuario tem algumas dessas permissoes isso é o some
            const hasAllPermissions = user.permissoes.some(permission => {
                return permissao.includes(permission);
            });

            //caso nao incluir
            if (!hasAllPermissions) {
                return false;
            }

            return true;
        }

        return false;
    }
    return true;
};
