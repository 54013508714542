import { GenericAbortSignal } from "axios";
import { urlHttpGov } from "../../urls";
import httpClient from "../httpClient";
import privateHttpClient from "../privateHttpClient";
import { interfSignInData } from "../../../interfaces";
import PanelUserMapper from "../mappers/PanelUserMapper";

class AuthService {
    private baseURL = `${urlHttpGov}/auth`;

    async signIn(data :interfSignInData) {
        const response = await httpClient.post(this.baseURL, data);
        const domainUser = PanelUserMapper.toDomain(response.data.user);
        return {
            ...response.data,
            panel_user: domainUser,
        };
    }

    async signInWithCode(code: string) {
        const response = await httpClient.post(`${urlHttpGov}/verification-code/login`, { code });
        const domainUser = PanelUserMapper.toDomain(response.data.user);

        return {
            ...response.data,
            panel_user: domainUser,
        };
    }

    refreshToken(refresh_token: string | undefined, signal: GenericAbortSignal | undefined) {
        return privateHttpClient.post(`${this.baseURL}/refresh_token`, { refresh_token }, { signal });
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
