import { ReactNode, createContext, useCallback, useEffect, useState } from "react";
import jwt_decode from 'jwt-decode';
import { interfCity, interfContract, interfPanelUser, interfSignInData } from "../interfaces";
import { EmitError } from "../utils/EmitError";

import privateHttpClient from "../services/http/privateHttpClient";
import useLocalStorage from "../hooks/useLocalStorage";
import { addMinutes } from "date-fns";
import { AxiosError } from "axios";
import { clearToasts } from "../utils/toast";
import AuthService from "../services/http/services/AuthService";

interface interfAuthContext {
    loading: boolean,
    user: interfPanelUser | undefined,
    signIn: (data: any) => Promise<boolean>,
    signInWithPhoneCode: (code: string) => Promise<boolean>,
    signOut: () =>  void,
    activeEntity: interfContract | undefined,
    setUseractiveEntity: (contract: interfContract) => void,
    isSelectCityModalOpen: boolean,
    handleOpenSelectCityModal: () => void,
    handleCloseSelectCityModal: () => void,
    userCan: (permission: string) => boolean,
}

export const AuthContext = createContext({} as interfAuthContext);

interface interfProps {
    children: ReactNode,
}

export function AuthProvider({ children }: interfProps) {

    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<interfPanelUser>();
    const [activeEntity, setActiveEntity] = useState<any | undefined>();
    const [isSelectCityModalOpen, setIsSelectCityModalOpen] = useState<boolean>(false);
    const {
        getToken,
        getRefreshToken,
        getUser,
        removeTokenFromLocalStorage,
        removeRefreshTokenFromLocalStorage,
        removeUserFromLocalStorage,
        getSelectedContractFromStorage,
        removeSelectedCityFromLocalStorage,
    } = useLocalStorage();

    const setUseractiveEntity = useCallback((entity: any) => {

        if (!entity) {
            return;
        }

        setActiveEntity(entity);

        var token = getToken();
        if(token)
            var decodedToken: any = jwt_decode(token);

        entity.id = decodedToken.entityId
        localStorage.setItem('@GovfacilGestor:selectedEntity', JSON.stringify(
            { entity }
        ));

    }, []);

    useEffect(() => {
        if (loading) {
            return;
        }
        if (user && !activeEntity) {
            setIsSelectCityModalOpen(false);
            return;
        }

        setIsSelectCityModalOpen(false);
    }, [activeEntity, loading, user]);

    const handleOpenSelectCityModal = useCallback(() => {
        setIsSelectCityModalOpen(true);
    }, []);

    const handleCloseSelectCityModal = useCallback(() => {
        setIsSelectCityModalOpen(false);
    }, []);

    const signIn = useCallback(async (data: interfSignInData): Promise<boolean> => {
        try {
            let response = await AuthService.signIn(data);

            localStorage.setItem('@GovfacilGestor:token', JSON.stringify(
                { token: response.token }
            ));

            localStorage.setItem('@GovfacilGestor:rftoken', JSON.stringify(
                { refresh_token: response.refresh_token }
            ));

            localStorage.setItem('@GovfacilGestor:user', JSON.stringify(
                { user: response.user }
            ));

            localStorage.setItem('@GovfacilGestor:selectedEntity', JSON.stringify(
                { entity: response.user.entity }
            ));

            setUseractiveEntity(response.user.entity);

            privateHttpClient.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;

            setUser(response.user);
            clearToasts();
            return true;
        } catch (err) {
            EmitError(err);
            return false;
        }
    }, []);

    const signInWithPhoneCode = useCallback(async (code: string): Promise<boolean> => {
        try {
            let response = await AuthService.signInWithCode(code);

            localStorage.setItem('@GovfacilGestor:token', JSON.stringify(
                { token: response.token }
            ));

            localStorage.setItem('@GovfacilGestor:rftoken', JSON.stringify(
                { refresh_token: response.refresh_token }
            ));

            localStorage.setItem('@GovfacilGestor:user', JSON.stringify(
                { user: response.user }
            ));

            localStorage.setItem('@GovfacilGestor:selectedEntity', JSON.stringify(
                { entity: response.user.entity }
            ));

            setUseractiveEntity(response.user.entity);

            privateHttpClient.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;
            setUser(response.user);
            clearToasts();
            return true;
        } catch (err) {
            EmitError(err);
            return false;
        }

    }, []);

    const signOut = useCallback(() => {
        removeTokenFromLocalStorage();
        removeRefreshTokenFromLocalStorage();
        removeUserFromLocalStorage();
        removeSelectedCityFromLocalStorage();
        privateHttpClient.defaults.headers.common['Authorization'] = undefined;
        setUser(undefined);
        setActiveEntity(undefined);
        clearToasts();
    }, [removeTokenFromLocalStorage, removeRefreshTokenFromLocalStorage, removeUserFromLocalStorage, removeSelectedCityFromLocalStorage]);

    const checkExpiredToken = useCallback((token: string | undefined): boolean => {
        if (token) {
            let decodedToken: any = jwt_decode(token);

            if (decodedToken != null) {
                // addMinutes(new Date(), 5).getTime() / 1000
                // if (decodedToken.exp < new Date().getTime() / 1000) {
                if (decodedToken.exp < addMinutes(new Date(), 3).getTime() / 1000) {
                    return true;
                }
            }
        } else if (token === undefined) {
            return true;
        }
        return false;
    }, []);

    const userCan = useCallback((namePermission: string): boolean => {
        return !!user?.permissions?.some(permission => permission.name === namePermission);
    }, [user]);

    useEffect(() => {
        setLoading(true);

        const storedUser = getUser();
        const storedToken = getToken();
        const storedSelectedContract = getSelectedContractFromStorage();
        if (storedUser) {
            setUser(storedUser);
            privateHttpClient.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
        }

        if (storedSelectedContract) {
            setActiveEntity(storedSelectedContract);
        }

        setLoading(false);
    }, [getUser, getToken, getSelectedContractFromStorage]);

    useEffect(() => {
        const requestIntercept = privateHttpClient.interceptors.request.use(async (config) => {
            if(config.url?.includes('refresh_token')) {
                return config;
            }

            if (getToken() !== undefined && checkExpiredToken(getToken())) {
                try {

                    const refreshToken = getRefreshToken()

                    let result = await AuthService.refreshToken(refreshToken, config.signal);

                    localStorage.setItem('@GovfacilGestor:token', JSON.stringify(
                        { token: result.data.token }
                    ));

                    privateHttpClient.defaults.headers.common['Authorization'] = `Bearer ${result.data.token}`;

                    if (result.data?.refresh_token &&
                        typeof result.data?.refresh_token !== 'undefined') {

                        localStorage.setItem('@GovfacilGestor:rftoken', JSON.stringify(
                            { refresh_token: result.data.refresh_token }
                        ));
                    }

                    if (config?.headers?.Authorization) {
                        config.headers.set('Authorization', 'Bearer ' + result.data.token);
                    }

                    return config;

                } catch (error) {
                    if (error instanceof AxiosError && error.name === 'CanceledError') {
                        return config;
                    }

                    signOut();
                    throw new Error('Usuário não autorizado.');
                }
            } else {
                return config;
            }
        }, function (error) {
            return Promise.reject(error);
        });

        return () => {
            privateHttpClient.interceptors.request.eject(requestIntercept);
        }
    }, [checkExpiredToken, getRefreshToken, getToken, signOut, userCan]);

    return (
        <AuthContext.Provider
            value={{
                loading,
                user,
                signIn,
                signInWithPhoneCode,
                signOut,
                activeEntity,
                setUseractiveEntity,
                isSelectCityModalOpen,
                handleOpenSelectCityModal,
                handleCloseSelectCityModal,
                userCan,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
