import { useCallback, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormInstance } from "antd";

import { interfCity, interfContract } from "../../interfaces";

import useAuth from "../../hooks/useAuth";
import { EmitSuccessAlert } from "../../utils/EmitSuccessAlert";
import { EmitError } from "../../utils/EmitError";
import ContractsService from "../../services/http/services/ContractsService";
import { useNavigate } from "react-router-dom";

interface interfInputs {
    contractId: number,
}

export default function useSelectCityModal() {
    const [cities, setCities] = useState<Array<interfCity>>([]);
    const [contracts, setContracts] = useState<Array<interfContract>>([]);
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
    const refForm = useRef<FormInstance>(null);
    const { user, activeEntity, setUseractiveEntity } = useAuth();
    const navigate = useNavigate();
    const handleSubmitForm: SubmitHandler<interfInputs> = async (data) => {
        try {
            setIsSubmiting(true);

            const selectedContract = contracts.find((contract) => contract.id === data.contractId);

            if (!selectedContract) {
                return;
            }


            const controller = new AbortController();

            const provisorio = await ContractsService.getContractById(selectedContract.id!, controller.signal);

            setUseractiveEntity(provisorio);
            navigate('/institucional');
            setIsSubmiting(false);

            EmitSuccessAlert('Contrato selecionado com sucesso!');

        } catch (err) {
            EmitError(err, true, 'selectCityModal_asdkasidaskd');
            setIsSubmiting(false);
        }
    };

    const {
        handleSubmit,
        control,
        formState: {
            errors
        }
    } = useForm<interfInputs>({
        defaultValues: {
            contractId: activeEntity?.id
        }
    });

    useEffect(() => {
        if (!user) {
            return;
        }

        const controller = new AbortController();

        async function loadContracts() {
            try {
                //const contractsList = await ContractsService.listContracts(controller.signal);

                //Busca os contratos que o usuário tem acesso
                //const userContracts = contractsList.filter((contract: any) => user?.contract.includes(contract.id));
                //setContracts(userContracts);

            } catch (err) {
                EmitError(err, true, 'selectCityModal_asdaskd');
            }
        }

        loadContracts();


        // setContracts(user.contract);
    }, [user]);

    const handleConfirmSelectContract = useCallback(() => {
        refForm.current?.submit();
    }, []);

    return {
        cities,
        contracts,
        isSubmiting,
        handleSubmit,
        handleSubmitForm,
        control,
        errors,
        refForm,
        handleConfirmSelectContract
    }
}
