import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ timer, setTimer }: any) => {
  useEffect(() => {
    let interval: any;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer: any) => prevTimer - 1);
      }, 1000);
    } else {
      // Quando o timer atinge zero, limpar o intervalo
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const formatTimer = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleResendCode = () => {
    // Lógica para reenviar o código (aqui é um exemplo, você deve ajustar conforme necessário)
    // onResendCode();
    // setTimer(initialTime); // Reiniciar o timer
  };

  if (timer === 0) {
    // Se o timer atingiu zero, você pode executar a lógica desejada aqui
    handleResendCode();
  }

  return  <label
  style={{
      fontSize: "15px",
      fontWeight: "600",
      color: timer !== 0 ? "#344" : "#0065AA",
  }}
>
{formatTimer()}
</label>;
};

export default CountdownTimer;
