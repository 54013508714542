import { interfPanelUser } from '../../../interfaces';

class PanelUserMapper {
    toPersistence(domainPanelUser: interfPanelUser): any {
        return {
            id: domainPanelUser.id,
            name: domainPanelUser.name,
            email: domainPanelUser.email,
            phone: domainPanelUser.phone,
            password: domainPanelUser.password,
            role_id: domainPanelUser.role_id,
            entity: domainPanelUser.entity,
            active: domainPanelUser.active,
            created_at: domainPanelUser.created_at,
            permissions: domainPanelUser.permissions,
        }
    }

    toDomain(persistencePanelUser: any): interfPanelUser {
        return {
            id: persistencePanelUser.id,
            name: persistencePanelUser.name,
            email: persistencePanelUser.email,
            phone: persistencePanelUser.phone,
            password: persistencePanelUser.password,
            role: persistencePanelUser.role,
            cities: persistencePanelUser.counties,
            entity: persistencePanelUser.entity,
            active: persistencePanelUser.active,
            created_at: persistencePanelUser.created_at,
            permissions: persistencePanelUser.permissions,
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PanelUserMapper();
