import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuProps } from "antd";
import {
    FileTextOutlined,
    LogoutOutlined,
    SettingOutlined,
    TeamOutlined,
    UserOutlined,
    EnvironmentOutlined,
    BulbOutlined,
    GiftOutlined,
} from '@ant-design/icons';

import useAuth from "../../hooks/useAuth";
import useLocalStorage from "../../hooks/useLocalStorage";
import { ThemeContext } from "../../contexts/ThemeContext";

type MenuItem = Required<MenuProps>['items'][number];

export default function useLayoutAdmin() {
    const navigate = useNavigate();
    const { signOut } = useAuth();
    const { getUser, getUserId } = useLocalStorage();

    const { updateTheme } = useContext(ThemeContext);
    const { handleOpenSelectCityModal, user, userCan } = useAuth();

    const [collapsed, setCollapsed] = useState(false);
    const [visibleDrawer, setvisibleDrawer] = useState(false);
    const [visibleDrawerColor, setVisibleDrawerColor] = useState(false);
    const [posicaoMenu, setPosicaoMenu] = useState('');
    const [abrirMenuLateral, setAbrirMenuLateral] = useState<Array<string>>(['']);
    const [itemsMenuPainelState, setItemsMenuPainelState] = useState<MenuItem[]>([]);
    const [collapsedWidth, setCollapsedWidth] = useState<number>(77);
    const [itemsMenuConfiguracaoState, setItemsMenuConfiguracaoState] = useState<MenuItem[]>([]);
    const [nomeUsuarioLogado, setNomeUsuarioLogado] = useState('');

    const getItem = useCallback((
        permissao: boolean,
        classNamePopUpDrawer: string,
        label: React.ReactNode,
        key?: React.Key | null,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem => {
        let popupClassName = classNamePopUpDrawer;
        return {
            key,
            popupClassName,
            icon,
            children,
            label,
            type,
            permissao: permissao.toString()
        } as MenuItem;
    }, []);

    const onCloseDrawer = useCallback((): void => {
        setvisibleDrawer(false);
    }, []);

    const clickNavigateMenuLateral = useCallback((rota: string): void => {
        navigate(rota);
        setvisibleDrawer(false);
    }, [navigate]);

    const onCollapse = useCallback((collapsedVal: boolean): void => {
        if (!collapsed) {
            setCollapsed(collapsedVal);
            setCollapsedWidth(77);
        } else {
            setCollapsed(collapsedVal);
            setCollapsedWidth(0);
        }
    }, [collapsed]);

    const onAbrirDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(true);
    }, []);

    const onCloseDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(false);
    }, []);

    const toggle = useCallback((): void => {
        if (collapsedWidth === 0) {
            setvisibleDrawer(!visibleDrawer);
        } else {
            setCollapsed(!collapsed);
        }
    }, [collapsedWidth, visibleDrawer, collapsed]);

    const clickMenuDireitoUsuarios = useCallback((): void => {
        navigate("/usuarios");
    }, [navigate]);

    const clickMenuPerfilUsuario = useCallback((): void => {
        let id = getUserId();
        navigate("/perfil");
    }, [getUserId, navigate]);

    const clickMenuDireitoChangeCity = useCallback((): void => {
        handleOpenSelectCityModal();
    }, [handleOpenSelectCityModal]);

    const clickMenuDireitoSair = useCallback((): void => {
        signOut();
    }, [signOut]);

    const onClickMenuConfiguracoes = useCallback((e: any): void => {
        switch (e.key) {
            case 'perfil1':
                clickMenuPerfilUsuario();
                break;
            case 'usuarios1':
                clickMenuDireitoUsuarios();
                break;
            case 'tema1':
                onAbrirDrawerColor();
                break;
            case 'sair1':
                clickMenuDireitoSair();
                break;
            default:
                break;
        }

    }, [clickMenuDireitoSair, clickMenuDireitoUsuarios, clickMenuPerfilUsuario, clickMenuDireitoChangeCity, onAbrirDrawerColor]);

    const onchangeRadioTheme = useCallback((value: any): void => {
        updateTheme(value.target.value)
    }, [updateTheme]);

    const clickMenuPrincipal = useCallback(({ key }: any): void => {

        setPosicaoMenu(key)

        switch (key) {
            case 'institucional':
                clickNavigateMenuLateral("/institucional");
                break;
            case 'noticias':
                clickNavigateMenuLateral("/noticias");
                break;
            case 'membros':
                clickNavigateMenuLateral("/membros");
                break;
            default:
                break;
        }
    }, [clickNavigateMenuLateral]);

    useEffect(() => {
        let dataUsuario = getUser();

        if (dataUsuario) {
            let arrayUrl = dataUsuario.name.split(" ")
            setNomeUsuarioLogado(arrayUrl[0])
        }

        let isDrawerOpned = '';

        if (typeof window !== 'undefined') {
            if (window.innerWidth < 768) {
                isDrawerOpned = 'dspclassMenuPopUpDraweer';
                setCollapsedWidth(0);
            }

            let arrayUrl = window.location.pathname.split("/");

            switch (arrayUrl[1]) {
                case "institucional":
                    setPosicaoMenu('institucional')
                    setAbrirMenuLateral([''])
                    break;
                case "noticias":
                    setPosicaoMenu('noticias')
                    setAbrirMenuLateral([''])
                    break;
                case "membros":
                    setPosicaoMenu('membros')
                    setAbrirMenuLateral([''])
                    break
                default:
                    setPosicaoMenu('')
                    setAbrirMenuLateral([''])
                    break;
            }
        }

        const itemsMenuConfiguracao: MenuItem[] = [
            getItem(true, isDrawerOpned, 'Tema', 'tema1', <FileTextOutlined />),
            // getItem(hasAccessToMoreThanOneCity, isDrawerOpned, 'Alterar Município', 'municipio1', <EnvironmentOutlined />),
            getItem(true, isDrawerOpned, 'Perfil', 'perfil1', <UserOutlined />),
            getItem(true, isDrawerOpned, 'Sair', 'sair1', <LogoutOutlined />),
        ];

        const itemsMenuPainel: MenuItem[] = [
            getItem(userCan('manage_entity'), isDrawerOpned, 'Institucional', 'institucional', <BulbOutlined />),
            getItem(userCan('manage_news'), isDrawerOpned, 'Notícias', 'noticias', <FileTextOutlined />),
            getItem(userCan('manage_member'), isDrawerOpned, 'Membros', 'membros', <TeamOutlined />),
        ];

        const menuConfig = itemsMenuConfiguracao.filter((element: any) => element?.permissao === 'true');
        setItemsMenuConfiguracaoState(menuConfig);

        const menuPainel = itemsMenuPainel.filter((element: any) => element?.permissao === 'true');
        setItemsMenuPainelState(menuPainel);

    }, [getUser, getItem, user]);

    return {
        onCloseDrawer,
        visibleDrawer,
        clickMenuPrincipal,
        posicaoMenu,
        itemsMenuPainelState,
        collapsedWidth,
        onCollapse,
        collapsed,
        setAbrirMenuLateral,
        abrirMenuLateral,
        onClickMenuConfiguracoes,
        toggle,
        itemsMenuConfiguracaoState,
        nomeUsuarioLogado,
        onCloseDrawerColor,
        visibleDrawerColor,
        onchangeRadioTheme,
    }
}
