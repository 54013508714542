import ToastMessage from '../ToastMessage';
import useToastContainer from './useToastContainer';

export default function ToastContainer() {
    const {
        messages,
        handleRemoveToast
    } = useToastContainer();

    return (
        <div
            style={{
                position: 'fixed',
                zIndex: 1099,
                top: '48px',
                right: '48px',
            }}
        >
            {messages.map((message) => 
                <ToastMessage 
                    key={message.id} 
                    message={message} 
                    onRemoveMessage={handleRemoveToast} 
                />
            )}
        </div>
    )
}
